import { supabase } from './supabase-config';
import invariant from 'tiny-invariant';

import { breadcrumb, log } from '@utils/logging';
import { Database } from '@type/database.types';
import { getSignedInUser } from './supabase-utils';
import { toast } from 'react-toastify';

const USER_TEAM = 'user_team';
const TEAMS = 'teams';
type DbTeam = Database['public']['Tables']['teams']['Row'];
// type TeamMembership = Database['public']['Tables']['user_team']['Row'];

export interface Team extends Omit<DbTeam, 'codeOfConduct'> {
    role: Database['public']['Enums']['role'];
    userStatus: Database['public']['Enums']['status'];
    codeOfConduct?: string;
}

export const getTeams = async (): Promise<Team[]> => {
    const user = await getSignedInUser();
    invariant(user, 'User must be signed in to get teams');

    const { data, error } = await supabase
        .from(USER_TEAM)
        .select(
            `
        role,
        userStatus:status,
        ...teams(
            id,
            name,
            description,
            imageUrl,
            joiningFee,
            createdAt,
            updatedAt
        )
    `,
        )
        .eq(`userId`, user.id);

    if (error) {
        log(`[getTeams] ${error.message}`, error);
        toast('Error fetching teams');
        return [];
    }

    breadcrumb('getTeams', { data });
    return data;
};

export async function updateTeam(
    team: Team | (Partial<Team> & Pick<Team, 'id'>),
    description = 'updateTeam',
): Promise<Team | null> {
    breadcrumb('Team', { team, description });

    // Toast.show({
    //     type: 'info',
    //     text1: `Updating ${description}...`,
    // });

    const { data, error } = await supabase.from(TEAMS).update(team).eq('id', team.id);

    if (error) {
        console.warn(error);
        // Toast.show({
        //     type: 'error',
        //     text1: `${description} failed to update`,
        //     text2: error.message,
        // });
    } else {
        console.log(`user (${description}) updated `, data);
        // Toast.show({
        //     type: 'success',
        //     text1: `${description} Updated!`,
        // });
    }

    return data;
}
