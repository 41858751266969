// import React, { useState } from 'react';
import { Carousel } from 'antd';
import Login from './login';
// import Signup from './signup';
import LogoSideLine from '../../assets/images/logo-side-line.svg';
import UserPlaceholder from '../../assets/images/user-placeholder.svg';
import MainLogo from '../../assets/images/logo.svg';
// import { selectLoginDetail } from './authSlice';
// import { STUDENT } from '../../utils/rolesConfig';
// import { useSelector } from 'react-redux';
// import { Navigate } from 'react-router-dom';
import './auth.css';

const Auth = () => {
    // const [showForm, setShowForm] = useState('login');

    // const user = useSelector(selectLoginDetail);

    // if (user) {
    //     const role = user.role;
    //     if (role === STUDENT[0]) {
    //         return <Navigate to="/dashboard" />;
    //     }
    //     return <Navigate to="/wrestlers" />;
    // }

    // const renderForm = () => {
    //     switch (showForm) {
    //         case 'login':
    //             return <Login setShowForm={setShowForm} />;
    //         case 'signUp':
    //             return <Signup setShowForm={setShowForm} />;
    //         case 'forgotPassword':
    //             return <ForgotPassword setShowForm={setShowForm} />;
    //         default:
    //             return <></>;
    //     }
    // };

    return (
        <div className="auth-inner-container">
            <div className="auth-left-wrapper">
                <div className="auth-left-wrapper-inner pt-5">
                    <div className="auth-left-content-ist-section">
                        <div className="auth-content-header">
                            <h1>
                                Tracking
                                <br />
                                <span className="font-style-italic">Wrestling</span>
                                <br />
                                Just Got Easier.
                            </h1>
                        </div>

                        <div className="testimonial-slider">
                            <Carousel
                                autoplay={true}
                                slidesToShow={1}
                                dots={true}
                                infinite={true}
                                className="bundle-slider"
                            >
                                <div className="testimonial-card">
                                    <div className="testimonial-content">
                                        <h3>Solid foundation for any project</h3>
                                        <p>
                                            &apos; This is a very complex and beautiful set of
                                            elements. Under the hood it comes with the best things
                                            from 2 worlds: Wrestling and Events. &apos;
                                        </p>
                                    </div>
                                    <div className="user-details">
                                        <div className="user-detail-inner">
                                            <div className="user-img">
                                                <img src={UserPlaceholder} />
                                            </div>

                                            <div className="user-info">
                                                <h3>Bonnie Green</h3>
                                                <p>Web developer @boutstream</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-card">
                                    <div className="testimonial-content">
                                        <h3>Solid foundation for any project</h3>
                                        <p>
                                            &apos; This is a very complex and beautiful set of
                                            elements. Under the hood it comes with the best things
                                            from 2 worlds: Wrestling and Events. &apos;
                                        </p>
                                    </div>
                                    <div className="user-details">
                                        <div className="user-detail-inner">
                                            <div className="user-img">
                                                <img src={UserPlaceholder} />
                                            </div>

                                            <div className="user-info">
                                                <h3>Bonnie Green</h3>
                                                <p>Web developer @boutstream</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>

                    <div className="logo-side-line pt-5">
                        <img src={LogoSideLine} />
                    </div>
                </div>
            </div>
            <div className="auth-right-wrapper">
                <div className="auth-form-wrapper">
                    <div className="auth-form-logo-wrapper">
                        <img src={MainLogo} />
                    </div>

                    <div className="auth-form-box">
                        <Login />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Auth;
