import React, { useEffect, useState } from 'react';
import Header from '../../components/layout/header';
import EventsCard from './eventCard';
import './events.css';
import {
    selectFetchPublicEventsLoading,
    selectPublicEventsList,
    getAllPublicEvents,
    resetPublicEventsSlice,
} from './eventsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Pagination } from 'antd';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const Events = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [defaultCurrent, setDefaultCurrent] = useState(1);
    const [eventPageSize, setEventPageSize] = useState(12);

    const isLoading = useSelector(selectFetchPublicEventsLoading);
    const publicEventsList = useSelector(selectPublicEventsList);

    useEffect(() => {
        fetchPublicEvents(0, eventPageSize);
        return () => {
            dispatch(resetPublicEventsSlice());
        };
    }, []);

    const fetchPublicEvents = (offset, limit) => {
        // const userId = user.accountId;
        // dispatch(getAllPublicEvents({ offset, limit, userId }));
    };

    const onChange = (page, pageSize) => {
        setDefaultCurrent(page);
        setEventPageSize(pageSize);
        const offset = pageSize * (page - 1);
        fetchPublicEvents(offset, pageSize);
    };

    const onMoreInfoClick = (event) => {
        const isJoin = publicEventsList?.eventsIds.includes(event?.id);
        navigate(`/events/event-details/${event.id}?isJoined=${isJoin}`);
    };

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                {' '}
                <Spin size="large" />
            </div>
        );
    } else if (
        publicEventsList?.publiceEvents &&
        publicEventsList?.publiceEvents?.data?.length < 1
    ) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                {' '}
                <h3>No event found</h3>
            </div>
        );
    }

    return (
        <>
            <Header pageTitle={'Public Events'} />
            <div className="main-container-inner p-4">
                <div className="row">
                    {publicEventsList?.publiceEvents &&
                        publicEventsList?.publiceEvents?.data?.length &&
                        publicEventsList?.publiceEvents?.data.map((event, index) => {
                            return (
                                <div className="col-md-3" key={index}>
                                    <EventsCard
                                        eventDetail={event}
                                        key={index}
                                        moreInfoAction={() => onMoreInfoClick(event)}
                                        selectedIds={publicEventsList?.eventsIds}
                                    />
                                </div>
                            );
                        })}
                </div>
                <div className="d-flex justify-content-center mt-4 mb-4">
                    <Pagination
                        defaultCurrent={defaultCurrent}
                        pageSize={eventPageSize}
                        total={publicEventsList?.publiceEvents?.total}
                        onChange={onChange}
                    />
                </div>
            </div>
        </>
    );
};

export default Events;
