import axios from 'axios';
import { apiEndpoint } from '@modules/config';
// import Cookies from 'js-cookie';

export const axiosInstance = axios.create({
    baseURL: apiEndpoint,
});

axiosInstance.interceptors.request.use(
    (config) => {
        // @ts-expect-error FIXME: fix this when setting up Supabase API
        config.headers = {
            ...config.headers,
            Accept: ' application/json;charset=utf-8',
            accept: 'application/json',
        };

        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

// This was causing infinite re-renders not sure what they were doing with it...
// axiosInstance.interceptors.response.use(
//     (response) => {
//         return Promise.resolve(response);
//     },
//     (error) => {
//         const statusCode = error.response.data.statusCode;
//         if (statusCode === 403) {
//             Cookies.remove('user');
//             window.location.reload();
//         }

//         return Promise.reject(error.response.data);
//     },
// );

const httpService = {
    create: axios.create,
    get: axiosInstance.get,
    post: axiosInstance.post,
    put: axiosInstance.put,
    delete: axiosInstance.delete,
    patch: axiosInstance.patch,
};

export default httpService;
