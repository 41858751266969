import React from 'react';
import { CheckCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import './payment.css';

const PaymentSuccess = () => {
    return (
        <div className="payment-page">
            <div className="payment-page-inner">
                <div className="payment-page-img success">
                    <CheckCircle size={100} />
                </div>
                <div className="payment-page-content success">
                    <h2>Payment Successfull!</h2>
                    <p>Thank You! Your payment has been received</p>
                    <Link to="/" className="btn-primary">
                        Return to Home
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default PaymentSuccess;
