import React, { useState } from 'react';
import {
    Form,
    Input,
    Button,
    DatePicker,
    TimePicker,
    InputNumber,
    Radio,
    AutoComplete,
} from 'antd';
import { format } from 'date-fns';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { selectAddUpdateEvent, addUpdateEvent } from './eventsSlice';
import useAuth from '../../hooks/useAuth';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const AddUpdateEventForm = ({ callBack, initialValues }) => {
    const [options, setOptions] = useState([]);

    const { TextArea } = Input;
    const dispatch = useDispatch();

    const isLoading = useSelector(selectAddUpdateEvent);
    const { authUser } = useAuth();

    const eventFormValues = {
        eventName: initialValues?.name,
        eventDate: dayjs(initialValues?.date),
        startTimeWeighIn: dayjs(initialValues?.startTimeWeighIn, 'hh:mm A'),
        startTimeBegin: dayjs(initialValues?.startTimeBegin, 'hh:mm A'),
        location: initialValues?.location,
        description: initialValues?.description,
        fee: initialValues?.fee,
        maxEntryNumber: initialValues?.maxEntryNumber,
        divisions: initialValues?.divisions,
        weightClasses: initialValues?.weightClasses,
        isPrivate: initialValues?.isPrivate,
        waiver: initialValues?.waiver,
    };
    const eventFormInitialValues = initialValues
        ? eventFormValues
        : {
              divisions: [''],
              weightClasses: [''],
              isPrivate: false,
              fee: 0,
          };

    const onFinish = (values) => {
        const eventId = initialValues?.id;
        const payload = {
            name: values.eventName,
            date: format(new Date(values.eventDate), 'MM/dd/yyyy'),
            startTimeWeighIn: dayjs(values.startTimeWeighIn).format('hh:mm a'),
            startTimeBegin: dayjs(values.startTimeBegin).format('hh:mm a'),
            location: values.location,
            description: values.description ? values.description : '',
            fee: values.fee,
            divisions: values.divisions,
            weightClasses: values.weightClasses,
            maxEntryNumber: values.maxEntryNumber,
            managerId: authUser.id,
            isPrivate: values.isPrivate,
            waiver: values.waiver,
            ...(eventId ? { id: eventId } : null),
        };

        dispatch(addUpdateEvent(payload)).then((response) => {
            if (response.meta.requestStatus === 'fulfilled') {
                callBack && callBack();
            }
        });
    };

    const handleSearch = (value) => {
        if (value.length > 2) {
            fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${value}`)
                .then((response) => response.json())
                .then((data) => {
                    const suggestions = data.map((item) => ({
                        value: item.display_name,
                    }));
                    setOptions(suggestions);
                });
        } else {
            setOptions([]);
        }
    };

    return (
        <Form
            name="addCoachesForm"
            onFinish={onFinish}
            autoComplete="off"
            layout={'vertical'}
            initialValues={eventFormInitialValues}
        >
            <div className="row">
                <div className="col-md-6">
                    <Form.Item
                        label="Event Name"
                        name="eventName"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter event name',
                            },
                        ]}
                    >
                        <Input size="large" placeholder="Enter Event Name" />
                    </Form.Item>
                </div>

                <div className="col-md-6">
                    <Form.Item
                        label="Event Date"
                        name="eventDate"
                        rules={[
                            {
                                required: true,
                                message: 'Please select event date',
                            },
                        ]}
                    >
                        <DatePicker
                            showToday={false}
                            placeholder="Select Date"
                            format={'MM/DD/YYYY'}
                            disabledDate={(current) =>
                                current.isBefore(format(new Date(), 'MM/dd/yyyy'))
                            }
                            allowClear={true}
                        />
                    </Form.Item>
                </div>

                <div className="col-md-6">
                    <Form.Item
                        label="Weigh Ins Start Time"
                        name="startTimeWeighIn"
                        rules={[
                            {
                                required: true,
                                message: 'Please select start time',
                            },
                        ]}
                    >
                        <TimePicker use12Hours format="h:mm a" />
                    </Form.Item>
                </div>

                <div className="col-md-6">
                    <Form.Item
                        label="Wrestling Start Time"
                        name="startTimeBegin"
                        rules={[
                            {
                                required: true,
                                message: 'Please select start time',
                            },
                        ]}
                    >
                        <TimePicker use12Hours format="h:mm a" />
                    </Form.Item>
                </div>

                <div className="col-12">
                    <Form.Item
                        label="Event Address"
                        name="location"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter event address',
                            },
                        ]}
                    >
                        <AutoComplete
                            options={options}
                            onSearch={handleSearch}
                            placeholder="Enter Event Address"
                            className="auto-complete-wrapper"
                        >
                            <Input size="large" placeholder="" />
                        </AutoComplete>
                    </Form.Item>
                </div>

                <div className="col-12">
                    <Form.Item label="Event Description" name="description">
                        <TextArea rows={4} placeholder="Event Description" />
                    </Form.Item>
                </div>

                <div className="col-md-6">
                    <Form.Item label="Fee" name="fee">
                        <InputNumber
                            formatter={(value) =>
                                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            size="large"
                            placeholder="Enter Event Fee"
                        />
                    </Form.Item>
                </div>

                <div className="col-md-6">
                    <Form.Item
                        label="Max Entry Number"
                        name="maxEntryNumber"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter max entry number',
                            },
                        ]}
                    >
                        <InputNumber size="large" placeholder="Max Entry Number" />
                    </Form.Item>
                </div>

                <div className="col-md-6">
                    <Form.Item label="Is Private event?" name={'isPrivate'}>
                        <Radio.Group>
                            <Radio value={false}> No</Radio>
                            <Radio value={true}> Yes </Radio>
                        </Radio.Group>
                    </Form.Item>
                </div>
                <hr />
                <div className="col-12">
                    <Form.List name="divisions">
                        {(fields, { add, remove }) => {
                            return (
                                <div>
                                    <div className="section-header">
                                        <h2>Divisions</h2>

                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            style={{ width: '100%' }}
                                            className="d-flex justify-content-center align-items-center"
                                        >
                                            <PlusOutlined /> Add division
                                        </Button>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <label htmlFor="" className="ant-form-item-required">
                                                Division Name
                                            </label>
                                        </div>

                                        <div className="col-md-3">Min Age</div>

                                        <div className="col-md-3">Max Age</div>
                                    </div>

                                    {fields.map((field, index) => (
                                        <div key={field.key} className="row">
                                            <div className="col-md-5">
                                                <Form.Item
                                                    name={[index, 'divisionName']}
                                                    className="mb-3"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter division name',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        size="large"
                                                        placeholder="Division Name"
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="col-md-3">
                                                <Form.Item
                                                    name={[index, 'min']}
                                                    className="mb-3"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Enter min age',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber
                                                        size="large"
                                                        placeholder="Min Age"
                                                    />
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-3">
                                                <Form.Item
                                                    name={[index, 'max']}
                                                    className="mb-3"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Enter max age',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber
                                                        size="large"
                                                        placeholder="Max Age"
                                                    />
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-1 d-flex justify-content-center">
                                                {fields.length > 1 ? (
                                                    <Button
                                                        type="danger"
                                                        className="dynamic-delete-button"
                                                        onClick={() => remove(field.name)}
                                                        icon={<MinusCircleOutlined />}
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            );
                        }}
                    </Form.List>
                </div>
                <hr />
                <div className="col-12">
                    <Form.List name="weightClasses">
                        {(fields, { add, remove }) => {
                            return (
                                <div>
                                    <div className="section-header">
                                        <h2>Weight Classes</h2>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            style={{ width: '100%' }}
                                            className="d-flex justify-content-center align-items-center"
                                        >
                                            <PlusOutlined /> Add weight class
                                        </Button>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <label htmlFor="" className="ant-form-item-required">
                                                Weight Class Name
                                            </label>
                                        </div>

                                        <div className="col-md-3">Max Weight</div>
                                    </div>

                                    {fields.map((field, index) => (
                                        <div key={field.key} className="row">
                                            <div className="col-md-5">
                                                <Form.Item
                                                    name={[index, 'className']}
                                                    className="mb-3"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Please enter weight class name',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        size="large"
                                                        placeholder="Weight Class Name"
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="col-md-4">
                                                <Form.Item
                                                    name={[index, 'max']}
                                                    className="mb-3"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Enter max weight',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber
                                                        size="large"
                                                        placeholder="Max Weight"
                                                    />
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-1 d-flex justify-content-center">
                                                {fields.length > 1 ? (
                                                    <Button
                                                        type="danger"
                                                        className="dynamic-delete-button"
                                                        onClick={() => remove(field.name)}
                                                        icon={<MinusCircleOutlined />}
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            );
                        }}
                    </Form.List>
                </div>
                <hr />
                <div className="col-12">
                    <Form.Item
                        label="Waiver"
                        name={'waiver'}
                        valuePropName="data"
                        getValueFromEvent={(_, editor) => editor.getData()}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter waiver',
                            },
                        ]}
                    >
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                height: `300px`,
                            }}
                        />
                    </Form.Item>
                </div>
            </div>

            <div className="col-12  mt-3">
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="w-100 btn-primary rounded-pill"
                        loading={isLoading}
                    >
                        {initialValues?.id ? 'Update' : 'Add'} event
                    </Button>
                </Form.Item>
            </div>
        </Form>
    );
};

export default AddUpdateEventForm;
