import React, { useMemo, useRef, useState } from 'react';
import { Drawer } from 'antd';
import CopyLinkCard from './CopyLinkCard';
import AddWrestlerForm from './AddWrestlerForm';
import './wrestlers.css';
import { useTeams } from '@providers/TeamProvider';
import MaterialTable, {
    materialTableHeight,
    TableActions,
} from '@components/molecules/MaterialTable';
import { Column, Options } from '@material-table/core';
import { CopyToClipboardColumn } from '@atoms/CopyToClipboardColumn';
import { readableDateFromDate } from '@utils/date-utils';
import { RenderEditButton } from '@atoms/RenderEditButton';
import FAB from '@atoms/FAB';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import { Athlete, TeamMember } from '@type/db-types';

const Wrestlers = () => {
    const { refreshMembers, athletes } = useTeams();
    const tableRef: React.MutableRefObject<undefined | unknown> = useRef();
    const [editingUser, setEditingUser] = useState<Athlete | boolean | undefined>(undefined);

    const tableColumns = useMemo(() => {
        return geTableColumns((data: Athlete) => setEditingUser(data));
    }, []);

    const successCallBack = () => {
        setEditingUser(undefined);

        // takes a minute for the updated info to trickle through
        // TODO: eventually we should hook this up to a watcher...
        setTimeout(() => refreshMembers(), 1000);
    };

    const actions: TableActions<TeamMember> = useMemo(
        () => [
            {
                icon: PersonAddAltRoundedIcon,
                tooltip: 'Add Wrestler',
                isFreeAction: true,
                onClick: () => setEditingUser(true),
            },
        ],

        [],
    );

    return (
        <>
            <div className="main-container-inner p-4">
                <CopyLinkCard
                    cardTitle={'Registration link'}
                    cardDescription={
                        'Share this link with your wrestlers to register them to your team.'
                    }
                    // copyLink={`${window.location.origin}/register-wrestler/${user.schoolId}`}
                    copyLink={`${window.location.origin}/register-wrestler`}
                />

                <MaterialTable<Athlete>
                    tableRef={tableRef}
                    actions={actions}
                    columns={tableColumns}
                    data={athletes || []}
                    localization={{
                        toolbar: {
                            nRowsSelected: (number) =>
                                `${number} wrestler${number === 1 ? '' : 's'} selected`,
                        },
                    }}
                    loading={athletes === undefined}
                    options={TABLE_OPTIONS}
                    // onRowClick={(e, rowData) => {
                    //     setSelecteduser(rowData);
                    // }}
                    tableId="BrandBadges"
                    title="Wrestlers"
                />
            </div>

            <Drawer
                title={`${(editingUser as Athlete)?.id ? 'Update' : 'Add'} wrestler`}
                placement="right"
                onClose={() => setEditingUser(undefined)}
                open={!!editingUser}
                width={'550px'}
                destroyOnClose={true}
            >
                <AddWrestlerForm
                    // schoolId={user?.schoolId}
                    onSuccess={successCallBack}
                    editingUser={editingUser}
                />
            </Drawer>
            <FAB onClick={() => setEditingUser(true)} toolTip="Add wrestler" />
        </>
    );
};

export default Wrestlers;

const TABLE_OPTIONS: Options<Athlete> = {
    actionsColumnIndex: -1,
    maxBodyHeight: `${materialTableHeight - 21}vh`,
    minBodyHeight: `${materialTableHeight - 21}vh`,
};

const geTableColumns = (onPressEdit: (data: Athlete) => void): Column<Athlete>[] => [
    { title: 'First Name', field: 'firstName' },
    { title: 'Last Name', field: 'lastName' },
    { title: 'Role', field: 'role' },
    { title: 'Gender', field: 'gender' },
    { title: 'Weight', field: 'weight' },
    { title: 'Status', field: 'teamStatus' },
    {
        title: 'Email',
        field: 'email',
        render: (data) => <CopyToClipboardColumn text={data.email || ''} />,
    },
    {
        field: 'userId',
        filtering: false,
        hidden: true,
        title: 'ID',
        render: (data) => <CopyToClipboardColumn text={data.userId || ''} />,
    },
    {
        title: 'Phone',
        field: 'phone',
        render: (data) => (data.phone ? <CopyToClipboardColumn text={data.phone} /> : '...'),
    },
    {
        title: 'Created At',
        hidden: true,
        field: 'createdAt',
        render: ({ userCreatedAt }) =>
            userCreatedAt ? readableDateFromDate(userCreatedAt) : userCreatedAt,
        type: 'numeric',
    },
    ...((onPressEdit
        ? [
              {
                  title: 'Edit',
                  field: 'edit',
                  render: (data) => (
                      <RenderEditButton<Athlete> onPressEdit={onPressEdit} {...data} />
                  ),
              },
          ]
        : []) as Column<Athlete>[]),
];
