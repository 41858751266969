import httpService from '../httpClient/httpService';

export const createEvent = async (payload) => {
    const response = await httpService.post('events', payload);
    return response.data;
};

export const updateEvent = async (payload) => {
    const eventId = payload.id;
    delete payload['id'];
    const response = await httpService.put(`events/${eventId}`, payload);
    return response.data;
};

export const fetchEventsList = async (query) => {
    const response = await httpService.get(`events?${query}`);
    return response.data;
};

export const fetchAllPublicEvents = async (query) => {
    const response = await httpService.get(`events/public?${query}`);
    return response.data;
};

export const fetchRegisterEventIdsForUser = async (userId) => {
    const response = await httpService.get(`events/registration/ids/user?userId=${userId}`);
    return response.data;
};

export const fetchRegisterUserIdsForEvent = async (eventId, schoolId) => {
    const response = await httpService.get(
        `events/registration/ids/event?eventId=${eventId}&schoolId=${schoolId}`,
    );
    return response.data;
};

export const fetchEventDetails = async (eventId) => {
    const response = await httpService.get(`events/${eventId}`);
    return response.data;
};

export const joinEvent = async (payload) => {
    const response = await httpService.post(`/events/registration`, payload);
    return response.data;
};

export const joinBulkEventEvent = async (payload) => {
    const response = await httpService.post(`/events/bulk-registration`, payload);
    return response.data;
};

export const getRegisterEventsForUser = async (query) => {
    const response = await httpService.get(`events/registration/user?${query}`);
    return response.data;
};

export const getRegisterdWrestlersForEvent = async (query) => {
    const response = await httpService.get(`events/registration/event?${query}`);
    return response.data;
};

export const eventService = {
    fetchEventsList,
    createEvent,
    fetchAllPublicEvents,
    updateEvent,
    fetchEventDetails,
    joinEvent,
    joinBulkEventEvent,
    getRegisterEventsForUser,
    fetchRegisterEventIdsForUser,
    fetchRegisterUserIdsForEvent,
    getRegisterdWrestlersForEvent,
};
