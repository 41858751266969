import React from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './layout.css';

interface Props {
    pageTitle?: string;
    buttonTitle?: string;
    buttonIcon?: React.ReactNode;
    buttonAction?: () => void;
}
const Header = ({ pageTitle, buttonTitle, buttonIcon = <PlusOutlined />, buttonAction }: Props) => {
    return (
        <>
            <header className="header">
                <h3 className="page-title">{pageTitle ? pageTitle : ''}</h3>
                {buttonAction && (
                    <Button
                        onClick={() => buttonAction()}
                        type="primary"
                        className="btn-primary rounded-pill d-flex align-items-center"
                    >
                        {buttonIcon ? buttonIcon : ''}
                        {buttonTitle ? buttonTitle : ''}
                    </Button>
                )}
            </header>
        </>
    );
};

export default Header;
