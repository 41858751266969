import Header from '../../components/layout/header';
import './dashboard.css';

const Dashboard = () => {
    return (
        <>
            <Header
                pageTitle={'Dashboard'}
                // buttonTitle={"Create event"}
                // buttonAction={add}
            />
            <div className="main-container-inner p-4">
                <h2>Student Dashboard</h2>
            </div>
        </>
    );
};

export default Dashboard;
