import React from 'react';
import { Tag } from 'antd';

const EventListDetail = ({ selectedEvent }) => {
    return (
        <div className="wrestler-detail-wrapper">
            <div className="wrestler-inner-detail">
                {selectedEvent?.firstName && (
                    <div className="row">
                        <div className="col-6">
                            <div className="detail-element">
                                <p className="label">Wrestler Name</p>
                                <p className="value">
                                    {selectedEvent?.firstName} {selectedEvent?.lastName}
                                </p>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="detail-element">
                                <p className="label">Wrestler Age</p>
                                <p className="value">{selectedEvent?.age}</p>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="detail-element">
                                <p className="label">Wrestler weight</p>
                                <p className="value">{selectedEvent?.weight}</p>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="detail-element">
                                <p className="label">Division</p>
                                <p className="value">{selectedEvent?.division}</p>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="detail-element">
                                <p className="label">Weight Class</p>
                                <p className="value">{selectedEvent?.weightClass}</p>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="detail-element">
                                <p className="label">Payment Status</p>
                                <p className="value">
                                    {selectedEvent?.paymentStatus === 'succeeded' ? (
                                        <Tag color="success">Success</Tag>
                                    ) : (
                                        <Tag color="error">Failed</Tag>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                )}

                <div className="row">
                    <div className="col-6">
                        <div className="detail-element">
                            <p className="label">Event Name</p>
                            <p className="value">{selectedEvent?.event?.name}</p>
                        </div>
                    </div>

                    <div className="ps-0 col-6">
                        <div className="detail-element">
                            <p className="label">Event Date</p>
                            <p className="value">{selectedEvent?.event?.date}</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="detail-element">
                            <p className="label">Weigh Ins Start Time</p>
                            <p className="value">{selectedEvent?.event?.startTimeWeighIn}</p>
                        </div>
                    </div>

                    <div className="ps-0 col-6">
                        <div className="detail-element">
                            <p className="label">Wrestling Start Time</p>
                            <p className="value">{selectedEvent?.event?.startTimeBegin}</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="detail-element">
                            <p className="label">Event Address</p>
                            <p className="value">{selectedEvent?.event?.location}</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="detail-element">
                            <p className="label">Event Description</p>
                            <p className="value">{selectedEvent?.event?.description}</p>
                        </div>
                    </div>
                </div>

                {!selectedEvent?.firstName && (
                    <>
                        <div className="row">
                            <div className="col-6">
                                <div className="detail-element">
                                    <p className="label">Event Fee</p>
                                    <p className="value">$ {selectedEvent?.event?.fee}</p>
                                </div>
                            </div>
                            <div className="ps-0 col-6">
                                <div className="detail-element">
                                    <p className="label">Max Entry Number</p>
                                    <p className="value">
                                        {selectedEvent?.event?.maxEntryNumber
                                            ? selectedEvent?.event?.maxEntryNumber
                                            : 'Unlimited'}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="detail-element">
                                    <div className="label-wrapper">
                                        <p className="label">Division Name</p>
                                        <p className="label">Max Age</p>
                                        <p className="label">Min Age</p>
                                    </div>
                                    {selectedEvent?.event?.divisions &&
                                        selectedEvent?.event?.divisions.map((item, index) => {
                                            return (
                                                <div className="value-wrapper" key={index}>
                                                    <p className="value">{item.divisionName}</p>
                                                    <p className="value">{item.max}</p>
                                                    <p className="value">{item.min}</p>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="detail-element">
                                    <div className="label-wrapper">
                                        <p className="label">Weight Class Name</p>
                                        <p className="label">Max weight</p>
                                    </div>
                                    {selectedEvent?.event?.weightClasses &&
                                        selectedEvent?.event?.weightClasses.map((item, index) => {
                                            return (
                                                <div className="value-wrapper" key={index}>
                                                    <p className="value">{item.className}</p>
                                                    <p className="value">{item.max}</p>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <div className="detail-element">
                                    <p className="label">Is private event</p>
                                    <p className="value">
                                        {selectedEvent?.event?.isPrivate ? 'Yes' : 'No'}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default EventListDetail;
