import { IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';

// interface EditButtonProps<Type> extends Type {
//     onPressEdit: (data: Type) => void;
// }
export function RenderEditButton<Type>({
    onPressEdit,
    ...data
}: {
    onPressEdit: (data: Type) => void;
} & Type) {
    return (
        <IconButton
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onPressEdit(data as Type);
            }}
            size="large"
        >
            <Edit />
        </IconButton>
    );
}
