import React, { useEffect } from 'react';
import { Table, Select } from 'antd';
import { calculateAge } from '../../utils/helpers';

const WrestlersListTableForbulkEvent = ({
    isLoading,
    wrestlerList,
    fetchWrestler,
    defaultCurrent,
    setDefaultCurrent,
    eventDetail,
    setIsRegsterButtonDisabled,
    setSelectedRows,
    selectedRows,
    setWrestlersDivision,
    wrestlersDivision,
    wrestlersWeightClass,
    setwestlersWeightClass,
}) => {
    useEffect(() => {
        const areAllSelectedRowsValid = selectedRows.every((row) => {
            const divisionSelected = wrestlersDivision.some((div) => div.wrestlerId === row.id);
            const weightClassSelected = wrestlersWeightClass.some(
                (cls) => cls.wrestlerId === row.id,
            );
            return divisionSelected && weightClassSelected;
        });

        setIsRegsterButtonDisabled(!areAllSelectedRowsValid);
    }, [selectedRows, wrestlersDivision, wrestlersWeightClass]);

    // Call set functions when initializing the selected options
    useEffect(() => {
        selectedRows.forEach((row) => {
            const age = calculateAge(row.dateOfBirth);
            const selectedDivision = getDivisionByAge(age)?.divisionName;
            const selectedClass = getClassByAge(row.weight)?.className;

            // Set default division if not already set
            if (!wrestlersDivision.some((div) => div.wrestlerId === row.id) && selectedDivision) {
                handleWrestlersDivisionChange(selectedDivision, row);
            }

            // Set default weight class if not already set
            if (!wrestlersWeightClass.some((cls) => cls.wrestlerId === row.id) && selectedClass) {
                handleWrestlersWeightClassChange(selectedClass, row);
            }
        });
    }, [selectedRows, eventDetail]);

    useEffect(() => {
        if (
            selectedRows.length > 0 &&
            checkifDivisionSelectedforSelectedRows() &&
            checkifWeightClassSelectedforSelectedRows()
        ) {
            setIsRegsterButtonDisabled(false);
        } else {
            setIsRegsterButtonDisabled(true);
        }
    }, [selectedRows, wrestlersDivision, wrestlersWeightClass]);

    const handleWrestlersDivisionChange = (value, record) => {
        const divisionObj = {
            wrestlerId: record.id,
            wrestlerDivision: value,
        };
        const filterDivision = wrestlersDivision.filter((item) => item.wrestlerId !== record.id);
        const updateDivision = [...filterDivision, divisionObj];
        setWrestlersDivision(updateDivision);
    };

    const handleWrestlersWeightClassChange = (value, record) => {
        const weightClassObj = {
            wrestlerId: record.id,
            wrestlerWeightClass: value,
        };
        const filterWeightClass = wrestlersWeightClass.filter(
            (item) => item.wrestlerId !== record.id,
        );

        const updateWeightClass = [...filterWeightClass, weightClassObj];
        setwestlersWeightClass(updateWeightClass);
    };

    const checkifDivisionSelectedforSelectedRows = () => {
        const selectedRowsIds = selectedRows.map((item) => item.id).sort((a, b) => a - b);
        const selectedwrestlersDivisionIds = wrestlersDivision
            .filter((item) => selectedRowsIds.includes(item.wrestlerId))
            .map((item) => item.wrestlerId)
            .sort((a, b) => a - b);

        if (selectedRowsIds.length !== selectedwrestlersDivisionIds.length) {
            return false;
        }

        for (let i = 0; i < selectedRowsIds.length; i++) {
            if (selectedRowsIds[i] !== selectedwrestlersDivisionIds[i]) {
                return false;
            }
        }
        return true;
    };

    const checkifWeightClassSelectedforSelectedRows = () => {
        const selectedRowsIds = selectedRows.map((item) => item.id).sort((a, b) => a - b);
        const selectedWrestlersWeightClassIds = wrestlersWeightClass
            .filter((item) => selectedRowsIds.includes(item.wrestlerId))
            .map((item) => item.wrestlerId)
            .sort((a, b) => a - b);

        if (selectedRowsIds.length !== selectedWrestlersWeightClassIds.length) {
            return false;
        }

        for (let i = 0; i < selectedRowsIds.length; i++) {
            if (selectedRowsIds[i] !== selectedWrestlersWeightClassIds[i]) {
                return false;
            }
        }
        return true;
    };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: wrestlerList?.userIds.includes(record.accountId),
        }),
    };

    const getDivisionByAge = (age) => {
        return eventDetail?.divisions.find((division) => age >= division.min && age < division.max);
    };

    const getClassByAge = (weight) => {
        return eventDetail?.weightClasses.find((cls) => weight < cls.max);
    };

    const columns = [
        {
            title: 'Wrestler Name',
            dataIndex: 'name',
            key: 'name',
            render: (value, record) => {
                return (
                    <>
                        {record?.firstName} {record?.lastName}
                    </>
                );
            },
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            render: (value) => {
                return <span className="text-capitalize">{value ? value : ''}</span>;
            },
        },
        {
            title: 'Age',
            dataIndex: 'dateOfBirth',
            key: 'dateOfBirth',
            render: (value) => {
                return <>{calculateAge(value)}</>;
            },
        },
        {
            title: 'Select Division',
            dataIndex: 'division',
            key: 'division',
            width: '170px',
            render: (value, record) => {
                const selectedDivision = getDivisionByAge(calculateAge(record.dateOfBirth));
                return (
                    <Select
                        defaultValue={selectedDivision?.divisionName}
                        placeholder="Select division"
                        onChange={(value) => handleWrestlersDivisionChange(value, record)}
                        options={eventDetail?.divisions.map((division) => {
                            return {
                                value: division?.divisionName,
                                label: division?.divisionName,
                            };
                        })}
                    />
                );
            },
        },

        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
        },
        {
            title: 'Select Weight Class',
            dataIndex: 'weightClass',
            key: 'weightClass',
            width: '200px',
            render: (value, record) => {
                const selectedClass = getClassByAge(record.weight);

                return (
                    <Select
                        defaultValue={selectedClass?.className}
                        placeholder="Select WeightClass"
                        onChange={(value) => handleWrestlersWeightClassChange(value, record)}
                        options={eventDetail?.weightClasses.map((weightClass) => {
                            return {
                                value: weightClass?.className,
                                label: weightClass?.className,
                            };
                        })}
                    />
                );
            },
        },
    ];

    return (
        <>
            <div className="table-wrapper mt-4 w-100">
                <Table
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    dataSource={wrestlerList?.wrestlersData?.data}
                    columns={columns}
                    rowKey="id"
                    scroll={{ y: 'calc(100vh - 500px)' }}
                    loading={isLoading}
                    rowClassName={(record, index) => {
                        if (wrestlerList?.userIds.includes(record.accountId)) {
                            return 'disabledRow';
                        }

                        return null;
                    }}
                    pagination={{
                        current: defaultCurrent,
                        total: wrestlerList?.wrestlersData?.total || 0,
                        onChange: (page, pageSize) => {
                            setDefaultCurrent(page);
                            const offset = pageSize * (page - 1);
                            fetchWrestler(offset, pageSize);
                        },
                        showSizeChanger: false,
                    }}
                />
            </div>
        </>
    );
};

export default WrestlersListTableForbulkEvent;
