import { Action, ColumnSortInfo, ColumnSortState, ReduxAppState } from '@type/app-types';
import { RESET_COLUMN_SORT, SET_COLUMN_SORT } from '../actions/redux-consts';

export const COLUMN_SORT_INITIAL_STATE: ColumnSortState = {
    recentColumnSort: {} as Record<string, ColumnSortInfo | undefined>,
};

export default function ColumnSortReducer(
    state = COLUMN_SORT_INITIAL_STATE,
    action: Action,
): ColumnSortState {
    switch (action.type) {
        case RESET_COLUMN_SORT:
            return {
                ...state,
                recentColumnSort: {},
            };
        case SET_COLUMN_SORT:
            return {
                ...state,
                recentColumnSort: {
                    ...state?.recentColumnSort,
                    [action.value.tableType]: action.value.columnSortInfo,
                },
            };
        default:
            return state;
    }
}

export const selectColumnSort = (state: ReduxAppState) => state.columnSortReducer?.recentColumnSort;
