import { Action, combineReducers, configureStore, Reducer, ThunkAction } from '@reduxjs/toolkit';
// @ts-expect-error switch to TS later...
import authSlice from '../pages/auth/authSlice';
// @ts-expect-error switch to TS later...
import eventsSlice from '../pages/Events/eventsSlice';
import { ColumnSortInfo, ReduxAppState } from '@type/app-types';
import columnSortReducer from './reducers/columnSortReducer';

const rootReducer = combineReducers({
    auth: authSlice, // TODO: review if we event want this
    columnSortReducer,
    events: eventsSlice, // TODO: review if we event want this
}) as unknown as Reducer<ReduxAppState, Action>;

const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
});

export { store };

export interface ColumnSortState {
    recentColumnSort: Record<string, ColumnSortInfo | undefined>;
}

export const RootState = rootReducer;
export const AppStore = store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
