import React from 'react';
import { AlertCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import './payment.css';

const PaymentFail = () => {
    return (
        <div className="payment-page">
            <div className="payment-page-inner">
                <div className="payment-page-img failed">
                    <AlertCircle size={100} />
                </div>
                <div className="payment-page-content fail">
                    <h2>Payment Failed</h2>
                    <p>Your payment has beed failed, please try again</p>
                    <Link to="/" className="btn-primary">
                        Return to Home
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default PaymentFail;
