import { useEffect, useMemo, useState } from 'react';
import { supabase } from './supabase-config';
import { breadcrumb, logError } from '@utils/logging';

export interface CredentialResponse {
    credential: string;
    select_by:
        | 'auto'
        | 'user'
        | 'user_1tap'
        | 'user_2tap'
        | 'btn'
        | 'btn_confirm'
        | 'btn_add_session'
        | 'btn_confirm_add_session';
}

// Sign in with email using Magic Link
export async function signInWithEmail(email: string) {
    // sanitize email string.
    email = email.trim().toLowerCase();

    const { data, error } = await supabase.auth.signInWithOtp({
        email,
        options: { emailRedirectTo: window.location.href },
    });

    console.log('signInWithEmail response', { data, error });

    if (error) {
        console.error('Error logging in:', error.message);
        return Promise.reject(error);
    } else {
        console.log('signInWithEmail returning data', { data });
        return data;
    }
}

// Sign in with Google
const handleSignInWithGoogle = (nonce: string) => async (response: CredentialResponse) => {
    const { data, error } = await supabase.auth.signInWithIdToken({
        provider: 'google',
        token: response.credential,
        nonce,
    });

    console.log('handleSignInWithGoogle response', { data, error });
};

// https://supabase.com/docs/guides/auth/social-login/auth-google#google-pre-built
// https://loops.so/docs/integrations/supabase#set-up-loops-smtp-in-supabase
// Adapted from https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
const getNonce = async () => {
    const nonce = btoa(String.fromCharCode(...crypto.getRandomValues(new Uint8Array(32))));
    const encoder = new TextEncoder();
    const encodedNonce = encoder.encode(nonce);
    const hashBuffer = await crypto.subtle.digest('SHA-256', encodedNonce);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashedNonce = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

    // Use 'hashedNonce' when making the authentication request to Google
    // Use 'nonce' when invoking the supabase.auth.signInWithIdToken() method

    return { hashedNonce, nonce };
};

// Hook to sign in with Google using the nonce
export const useSignInWithGoogle = () => {
    const [localHashedNonce, setHashedNonce] = useState<string>('');
    const [localNonce, setNonce] = useState<string>('');

    useEffect(() => {
        (async () => {
            const { hashedNonce, nonce } = await getNonce();
            setNonce(nonce);
            setHashedNonce(hashedNonce);
        })().catch((error: unknown) => {
            breadcrumb('Error getting nonce:');
            if (error instanceof Error) logError(error);
        });
    }, []);

    return useMemo(
        () => ({
            googleHtmlNonce: localHashedNonce,
            onSignInWithGoogle: handleSignInWithGoogle(localNonce),
        }),
        [localHashedNonce, localNonce],
    );
};
