import React, { useEffect, useState, useRef } from 'react';
import Header from '../../components/layout/header';
import './events.css';
import {
    selectEventDetailsLoading,
    selectEventDetails,
    getEventDetails,
    resetEventDetails,
} from './eventsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Button } from 'antd';
import eventBg from '../../assets/images/event-bg.jpg';
import { Calendar, MapPin, Clock } from 'react-feather';
import { useParams } from 'react-router-dom';
import { CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QRCode from 'react-qr-code';
import { toPng } from 'html-to-image';

const MyEventDetail = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const qrRef = useRef();

    const isLoading = useSelector(selectEventDetailsLoading);
    const eventDetails = useSelector(selectEventDetails);

    const [copyText, setCopyText] = useState('Copy Event Link');
    const [copyQRText, setCopyQRText] = useState('Copy Event QR Code');
    const copyLink = eventDetails
        ? `${window.location.origin}/events/event-details/${eventDetails.id}`
        : '';

    useEffect(() => {
        if (id) {
            dispatch(getEventDetails(id));
        }

        return () => {
            dispatch(resetEventDetails());
        };
    }, [id]);

    const handleCopyText = () => {
        setCopyText('Copied!');
        setTimeout(() => {
            setCopyText('Copy Event Link');
        }, 2000);
    };

    const copyToClipboard = () => {
        setCopyQRText('Copied!');
        const svg = qrRef.current.querySelector('svg');
        if (svg) {
            toPng(svg)
                .then((dataUrl) => {
                    fetch(dataUrl)
                        .then((res) => res.blob())
                        .then((blob) => {
                            const item = new ClipboardItem({ 'image/png': blob });
                            navigator.clipboard.write([item]);
                        });
                })
                .catch((err) => {
                    console.error('Failed to copy QR code', err);
                });
        }
        setTimeout(() => {
            setCopyQRText('Copy Event QR Code');
        }, 2000);
    };

    return (
        <>
            <Header pageTitle={'Event Detail'} />
            {!isLoading && (
                <div className="event-header">
                    <div className="event-bg-wrapper">
                        <img src={eventBg} alt="" />
                    </div>
                    <div className="event-details my-event-details">
                        <div className="event-card-time-locaion">
                            <p>
                                <span className="icon">
                                    <Calendar />
                                </span>{' '}
                                {eventDetails?.date}
                            </p>
                            <h3>{eventDetails?.name}</h3>
                            <p>
                                <span className="icon">
                                    <MapPin />{' '}
                                </span>{' '}
                                {eventDetails?.location}
                            </p>
                        </div>
                    </div>
                </div>
            )}

            <div className="main-container-inner my-event-details-containter p-5">
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <Spin size="large" />
                    </div>
                ) : (
                    <>
                        <div className="my-event-sub-details-wrapper">
                            <div className="event-sub-inner">
                                <div className="event-sub-left">
                                    <div className="event-timing-left">
                                        <div className="event-sub-left-inner">
                                            <h6>Weigh Ins Start Time</h6>
                                            <p className="m-0">
                                                {' '}
                                                <Clock size={16} /> {eventDetails?.startTimeWeighIn}
                                            </p>
                                        </div>
                                        <div className="event-sub-left-inner">
                                            <h6>Wrestling Start Time</h6>
                                            <p className="m-0">
                                                {' '}
                                                <Clock size={16} /> {eventDetails?.startTimeBegin}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="event-fee-left">
                                        <div className="event-sub-left-inner">
                                            <h6>Event Fee</h6>
                                            <p className="m-0">${eventDetails?.fee}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="event-sub-right">
                                    <CopyToClipboard
                                        text={copyLink}
                                        onCopy={() => handleCopyText()}
                                    >
                                        <Button
                                            type="primary"
                                            className="btn-primary rounded-pill d-flex align-items-center"
                                        >
                                            {copyText === 'Copied!' ? (
                                                <CheckOutlined />
                                            ) : (
                                                <CopyOutlined />
                                            )}
                                            {copyText}
                                        </Button>
                                    </CopyToClipboard>

                                    <div
                                        ref={qrRef}
                                        style={{
                                            position: 'absolute',
                                            top: '-9999px',
                                            left: '-9999px',
                                        }}
                                    >
                                        <QRCode value={copyLink} />
                                    </div>
                                    <h3>OR</h3>
                                    <Button
                                        type="primary"
                                        className="btn-primary rounded-pill d-flex align-items-center"
                                        onClick={copyToClipboard}
                                    >
                                        {copyQRText === 'Copied!' ? (
                                            <CheckOutlined />
                                        ) : (
                                            <CopyOutlined />
                                        )}
                                        {copyQRText}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="my-event-details-wrapper">
                            <div className="event-details-wrapper left">
                                <div className="event-description">
                                    <div className="event-description">
                                        <h3>Event Description</h3>
                                        <p>{eventDetails?.description}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="event-details-wrapper right">
                                <div className="event-sub-left-inner">
                                    <h6>Location</h6>
                                    <p className="m-0">{eventDetails?.location}</p>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default MyEventDetail;
