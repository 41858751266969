import { useState, useRef } from 'react';
import { Button } from 'antd';
import { CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QRCode from 'react-qr-code';
import { toPng } from 'html-to-image';

const CopyLinkCard = ({ cardTitle, cardDescription, copyLink }: any) => {
    const [copyText, setCopyText] = useState('Copy Link');
    const [copyQRText, setCopyQRText] = useState('Copy QR Code');

    const handleCopyText = () => {
        setCopyText('Copied!');
        setTimeout(() => {
            setCopyText('Copy  Link');
        }, 2000);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const qrRef = useRef<any>();

    const copyToClipboard = () => {
        setCopyQRText('Copied!');
        const svg = qrRef?.current ? qrRef.current.querySelector('svg') : null;
        if (svg) {
            toPng(svg)
                .then((dataUrl) => {
                    fetch(dataUrl)
                        .then((res) => res.blob())
                        .then((blob) => {
                            const item = new ClipboardItem({ 'image/png': blob });
                            navigator.clipboard.write([item]);
                        });
                })
                .catch((err) => {
                    console.error('Failed to copy QR code', err);
                });
        }
        setTimeout(() => {
            setCopyQRText('Copy QR Code');
        }, 2000);
    };

    return (
        <div className="copy-card-wrapper">
            <div className="copy-card-content">
                <h3>{cardTitle ? cardTitle : ''}</h3>
                <p>{cardDescription ? cardDescription : ''}</p>
            </div>
            <div className="copy-card-input-wrapper">
                <div className="input-wrapper">{copyLink ? copyLink : ''}</div>
                <CopyToClipboard text={copyLink} onCopy={() => handleCopyText()}>
                    <Button
                        type="primary"
                        className="btn-primary rounded-pill d-flex align-items-center"
                    >
                        {copyText === 'Copied!' ? <CheckOutlined /> : <CopyOutlined />}
                        {copyText}
                    </Button>
                </CopyToClipboard>

                <div ref={qrRef} style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}>
                    <QRCode value={copyLink} />
                </div>
                <h3>OR</h3>
                <Button
                    type="primary"
                    className="btn-primary rounded-pill d-flex align-items-center"
                    onClick={copyToClipboard}
                >
                    {copyQRText === 'Copied!' ? <CheckOutlined /> : <CopyOutlined />}
                    {copyQRText}
                </Button>
            </div>
        </div>
    );
};

export default CopyLinkCard;
