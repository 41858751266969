import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import AuthProvider from '@providers/AuthProvider';
import AppRoutes from '@pages/AppRoutes';
import { ThemeProvider } from '@mui/material/styles';
import theme from '@modules/theme';
import { UserProvider } from '@providers/UserProvider';
import { TeamProvider } from '@providers/TeamProvider';

function App() {
    return (
        <AuthProvider>
            <UserProvider>
                <TeamProvider>
                    <ThemeProvider theme={theme}>
                        <AppRoutes />
                    </ThemeProvider>
                </TeamProvider>
            </UserProvider>
        </AuthProvider>
    );
}

export default App;
