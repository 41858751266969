import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '@hooks/useAuth';
import OnboardingModal from '@components/onboarding/OnboardingModal';
import { useUser } from '@providers/UserProvider';

const AuthGuard = ({ children }: { children: React.JSX.Element }) => {
    const { authUser, loading } = useAuth();
    const { user } = useUser();

    // FIXME: This is a temporary solution to check if the user is approved by the super coach.
    // const isApprovedBySuperCoach = Boolean(true);
    // const role = auth?.role;

    // const redirectionUrl = window.location.href;
    // if (redirectionUrl.includes('/event-details') && !user) {
    //     return <Navigate to={`/auth?redirect=${redirectionUrl}`} />;
    // }

    if (loading) {
        return null;
    }

    if (!authUser) {
        return <Navigate to="/auth" />;
    }

    const onboardingComplete = !user?.firstName || !user?.lastName;

    // TODO: enable onboarding modal
    if (false && !onboardingComplete) {
        return <OnboardingModal />;
    }

    // if (!isApprovedBySuperCoach) {
    //     return <NotVerified />;
    // }

    return children;
};

export default AuthGuard;
