import { useState } from 'react';
import Header from '../../components/layout/header';
import './setting.css';
import { Button, Form, InputNumber, Spin } from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import { useTeams } from '@providers/TeamProvider';
import { Team, updateTeam } from '@supa/teams';
import { log } from '@utils/logging';
import { useNavigate } from 'react-router-dom';

const Settings = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { loading, selectedTeam } = useTeams();
    const [form] = Form.useForm(); // Use form instance for setting initial values
    const navigate = useNavigate();

    const onSettingUpdate = async (values: { teamJoiningFee: number; codeOfConduct: string }) => {
        if (!selectedTeam?.id) {
            toast.error('No team selected');
            log('[onSettingUpdate] No team selected', { selectedTeam });
            navigate('/');
            return;
        }

        const payload: Partial<Team> & Pick<Team, 'id'> = {
            id: selectedTeam.id,
            joiningFee: values.teamJoiningFee,
            codeOfConduct: values.codeOfConduct,
        };

        try {
            setIsLoading(true);
            await updateTeam(payload);
            toast.success('Setting updated successfully');
        } catch (error) {
            toast.error('Unable to update setting');

            console.log('error', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Header pageTitle={'Settings'} />
            <div className="main-container-inner p-4">
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <Spin size="large" />
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-md-8 col-sm-10 col-12">
                            <Form
                                name="addWrestlerForm"
                                onFinish={onSettingUpdate}
                                autoComplete="off"
                                layout={'vertical'}
                                form={form}
                            >
                                <Form.Item
                                    label="Team Joining Fee"
                                    name="teamJoiningFee"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input team joining fee',
                                        },
                                    ]}
                                >
                                    <InputNumber prefix="$" size="large" placeholder="Enter Fee" />
                                </Form.Item>

                                <Form.Item
                                    label="Code of Conduct"
                                    name={'codeOfConduct'}
                                    valuePropName="data"
                                    getValueFromEvent={(_, editor) => editor.getData()}
                                >
                                    <CKEditor editor={ClassicEditor} />
                                </Form.Item>

                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="w-100 btn-primary rounded-pill mt-3"
                                        loading={isLoading}
                                    >
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Settings;
