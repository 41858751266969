import { useState } from 'react';
import { toast } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Button, ThemeProvider, Typography, createTheme } from '@mui/material';
import { secondaryDark } from '@modules/theme';

export function CopyToClipboardColumn({ text }: { text: string }) {
    const [show, setShow] = useState(true);
    return (
        <ThemeProvider theme={theme}>
            <Button
                sx={{
                    ['&:hover']: {
                        display: 'block !important',
                    },
                    color: 'rgba(0, 0, 0, 0.87)',
                    display: 'flex',
                    flexDirection: 'row',
                }}
                onClick={(e) => {
                    navigator.clipboard.writeText(text);
                    e.preventDefault();
                    e.stopPropagation();
                    toast.info(`text copied: ${text}`);
                }}
                onMouseOver={() => setShow(true)}
                onMouseOut={() => setShow(false)}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <Typography>{text}</Typography>
                    <ContentCopyIcon
                        sx={{
                            display: show ? 'block' : 'none',
                            marginLeft: '.5rem',
                            color: secondaryDark,
                        }}
                    />
                </Box>
            </Button>
        </ThemeProvider>
    );
}

const theme = createTheme({
    typography: {
        button: {
            textTransform: 'none',
        },
    },
});
