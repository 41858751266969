import { APP_ENV, VERSION } from '@modules/config';
import { secondaryGray } from '@modules/theme';

interface Props {
    small?: boolean;
}
export default function VersionInfo({ small }: Props) {
    const env = APP_ENV[0].toUpperCase();

    return <p style={small ? SMALL : LARGE}>{`v${VERSION} [${env}]`}</p>;
}

const SMALL = { fontSize: '.6rem', color: secondaryGray, textAlign: 'center', marginBottom: 0 };
const LARGE = { fontSize: '1rem', color: secondaryGray };
