import { getUser } from '@supa/users';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { stringFromError } from '@utils/utils';
import { log } from '@utils/logging';
import useAuth from '@hooks/useAuth';
import { User } from '@type/db-types';

// FIXME: unify with db types
export enum UserRole {
    STUDENT = 'STUDENT',
    COACH = 'COACH',
    SUPER_COACH = 'SUPER_COACH', // previously required for editing settings
}
interface UserContextValues {
    loading: boolean;
    user: User | undefined;
    roles: UserRole[];
}
const TESTING_ROLES = [UserRole.SUPER_COACH, UserRole.COACH, UserRole.STUDENT];
const UserContext = createContext<UserContextValues>({
    loading: true,
    user: undefined,
    roles: TESTING_ROLES,
});

export function UserProvider({ children }: { children: React.JSX.Element }): React.JSX.Element {
    const [user, setUser] = useState<User | undefined>();
    const { authUser } = useAuth();

    useEffect(() => {
        const fetchUser = async () => {
            if (authUser?.id) {
                const newUser = await getUser(authUser.id);

                console.log('newUser', newUser);
                setUser(newUser);
            }
        };
        // fetch user once auth has loaded
        fetchUser().catch((e: unknown) => log(`[fetchUser]: ${stringFromError(e)}`));
    }, [authUser?.id]);

    const userContext: UserContextValues = useMemo(() => {
        return {
            loading: user === undefined,
            user,
            roles: TESTING_ROLES,
        };
    }, [user]);

    return <UserContext.Provider value={userContext}>{children}</UserContext.Provider>;
}

// Access user context values and functions
export const useUser = () => {
    const ctx = useContext(UserContext);

    if (!ctx) console.error('useUser called outside of UserProvider Context');

    return ctx;
};
