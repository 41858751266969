import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useAuth from '@hooks/useAuth';
import { getTeams, Team } from '@supa/teams';
import { getTeamAthletes, getTeamMembers } from '@supa/users';
import { stringFromError } from '@utils/utils';
import { log } from '@utils/logging';
import { Athlete, COACH_ROLE, TEAM_ROLE, TeamMember } from '@type/db-types';

interface TeamContextValues {
    loading: boolean;
    teams: Team[] | undefined;
    selectedTeam: Team | undefined;
    teamMembers: TeamMember[] | undefined;
    athletes: Athlete[] | undefined;
    coaches: TeamMember[] | undefined;
    parents: TeamMember[] | undefined;
    refreshTeams: () => void;
    refreshMembers: () => void;
    userMembershipInfo: TeamMember | undefined;
    role: TEAM_ROLE | undefined;
}
const TeamContext = createContext<TeamContextValues>({
    loading: true,
    teams: undefined,
    selectedTeam: undefined,
    teamMembers: undefined,
    athletes: undefined,
    coaches: undefined,
    parents: undefined,
    refreshTeams: () => {},
    refreshMembers: () => {},
    userMembershipInfo: undefined,
    role: undefined,
});

export function TeamProvider({ children }: { children: React.JSX.Element }): React.JSX.Element {
    const [teams, setTeams] = useState<Team[] | undefined>();
    const [selectedTeam, setSelectedTeam] = useState<Team | undefined>();
    const [teamMembers, setTeamMembers] = useState<TeamMember[] | undefined>(undefined);
    const [athletes, setAthletes] = useState<Athlete[] | undefined>(undefined);
    const [coaches, setCoaches] = useState<TeamMember[] | undefined>(undefined);
    const [parents, setParents] = useState<TeamMember[] | undefined>(undefined);
    const { authUser } = useAuth();
    const userMembershipInfo = useMemo(
        () => teamMembers?.find(({ userId }) => userId === authUser?.id),
        [authUser?.id, teamMembers],
    );
    const role = userMembershipInfo?.role;

    const refreshTeams = useCallback(
        () =>
            getTeams()
                .then((updatedTeams) => {
                    setTeams(updatedTeams);

                    // auto-select the team if there is only one
                    if (updatedTeams.length === 1) {
                        setSelectedTeam(updatedTeams[0]);
                    }
                })
                .catch((e) => {
                    log(`[refreshTeams] ${stringFromError(e)}`);
                }),
        [],
    );

    const refreshMembers = useCallback(
        () =>
            Promise.all([getTeamMembers(selectedTeam?.id), getTeamAthletes(selectedTeam?.id)])
                .then(([members, athletes]) => {
                    setTeamMembers(members);
                    setAthletes(athletes);
                    setParents(members.filter(({ role }) => role === 'parent'));
                    setCoaches(members.filter(({ role }) => COACH_ROLE.includes(role)));
                })
                .catch((e) => {
                    log(`[refreshMembers] ${stringFromError(e)}`);
                }),
        [selectedTeam?.id],
    );

    useEffect(() => {
        // fetch the user's teams
        if (authUser?.id) refreshTeams();
    }, [authUser?.id, refreshTeams]);

    useEffect(() => {
        if (selectedTeam) {
            refreshMembers();
        }
    }, [refreshMembers, selectedTeam]);

    const teamContext: TeamContextValues = useMemo(() => {
        return {
            loading: teams === undefined,
            teams,
            selectedTeam,
            teamMembers,
            athletes,
            coaches,
            parents,
            refreshTeams,
            refreshMembers,
            userMembershipInfo,
            role,
        };
    }, [
        athletes,
        coaches,
        parents,
        refreshMembers,
        refreshTeams,
        selectedTeam,
        teamMembers,
        teams,
        userMembershipInfo,
        role,
    ]);

    return <TeamContext.Provider value={teamContext}>{children}</TeamContext.Provider>;
}

// Access team context values and functions
export const useTeams = () => {
    const ctx = useContext(TeamContext);

    if (!ctx) console.error('useTeams called outside of TeamProvider Context');

    return ctx;
};
