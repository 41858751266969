import React, { useState } from 'react';
import { Table, Drawer, Button, Tag, Dropdown, Space } from 'antd';
import { EyeOutlined, SendOutlined, DownOutlined } from '@ant-design/icons';
import EventListDetail from './eventListDetail';
import { stripeService } from '../../api/services/stripe.services';
import useAuth from '../../hooks/useAuth';

const RegisteredEventsListTable = ({
    isLoading,
    eventsList,
    fetchEvents,
    defaultCurrent,
    setDefaultCurrent,
}) => {
    const [openDetailEventDrawer, setOpenDetailEventDrawer] = useState(false);
    const [isPayLoading, setIsPayLoading] = useState(false);
    const [selectedEventDetail, setSelectedEventDetail] = useState(null);
    const { authUser } = useAuth();

    const showWresterlerDetails = (selectedRecord) => {
        setSelectedEventDetail(selectedRecord);
        setOpenDetailEventDrawer(true);
    };

    const hideWresterlerDetails = () => {
        setSelectedEventDetail(null);
        setOpenDetailEventDrawer(false);
    };

    const performPayment = async (record) => {
        setIsPayLoading(true);
        const payload = {
            lineItems: [
                {
                    price_data: {
                        currency: 'usd',
                        product_data: {
                            name: `${record.firstName} ${record.lastName} registration in ${record.event.name}`,
                        },
                        unit_amount: record.event.fee * 100,
                    },
                    quantity: 1,
                },
            ],
            payment_intent_data: {
                metadata: {
                    payment_type: 'tournament_entry_payment',
                    eventId: record?.event?.id,
                    userIds: authUser?.accountId,
                },
            },
        };

        try {
            const response = await stripeService.doStripePayment(payload);
            window.location.href = response.url;
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsPayLoading(false);
        }
    };

    const columns = [
        {
            title: 'Event Name',
            dataIndex: 'name',
            key: 'name',
            render: (value, record) => {
                return record?.event.name;
            },
        },
        {
            title: 'Wrestler Name',
            dataIndex: 'name',
            key: 'name',
            render: (value, record) => {
                return `${record?.firstName} ${record?.lastName} `;
            },
        },
        {
            title: 'Address',
            dataIndex: 'location',
            key: 'location',
            render: (value, record) => {
                return record?.event.location;
            },
        },
        {
            title: 'Weigh Ins Start Time',
            dataIndex: 'startTimeWeighIn',
            key: 'startTimeWeighIn',
            render: (value, record) => {
                return record?.event.startTimeWeighIn;
            },
        },
        {
            title: 'Wrestling Start Time',
            dataIndex: 'startTimeBegin',
            key: 'startTimeBegin',
            render: (value, record) => {
                return record?.event.startTimeBegin;
            },
        },
        {
            title: 'Fee',
            dataIndex: 'fee',
            key: 'fee',
            render: (value, record) => {
                return `$ ${record?.event.fee}`;
            },
        },
        {
            title: 'Payment Status',
            dataIndex: 'paymentStatus',
            key: 'paymentStatus',
            render: (value, record) => {
                return record?.paymentStatus === 'succeeded' ? (
                    <Tag color="success">Success</Tag>
                ) : (
                    <Tag color="error">Failed</Tag>
                );
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (value, record) => {
                return (
                    <Dropdown menu={renderActionMenu(record)} trigger={['click']}>
                        <Button type="default">
                            <Space>
                                Actions
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    const renderActionMenu = (record) => {
        let items = [
            {
                label: (
                    <Button
                        type="link"
                        className="px-1 d-flex gap-0 align-items-center"
                        onClick={() => {
                            showWresterlerDetails(record);
                        }}
                    >
                        <EyeOutlined size={18} />
                        View Details
                    </Button>
                ),
                key: '0',
            },
        ];

        if (!record?.paymentStatus || !record?.paymentStatus === 'failed') {
            items.splice(1, 0, {
                label: (
                    <Button
                        type="link"
                        className="px-1 d-flex gap-0 align-items-center"
                        onClick={() => {
                            performPayment(record);
                        }}
                    >
                        <SendOutlined size={18} /> Pay Now
                    </Button>
                ),
                key: '1',
            });
        }
        return { items };
    };
    return (
        <>
            <div className="table-wrapper mt-4 w-100">
                <h3>Registered Events</h3>
                <Table
                    dataSource={eventsList?.data}
                    columns={columns}
                    rowKey="id"
                    scroll={{ y: 'calc(100vh - 100px)' }}
                    loading={isLoading || isPayLoading}
                    pagination={{
                        current: defaultCurrent,
                        total: eventsList?.total || 0,
                        onChange: (page, pageSize) => {
                            setDefaultCurrent(page);
                            const offset = pageSize * (page - 1);
                            fetchEvents(offset, pageSize);
                        },
                        showSizeChanger: false,
                    }}
                />
                <Drawer
                    open={openDetailEventDrawer}
                    title="Event Details"
                    placement="right"
                    onClose={() => hideWresterlerDetails()}
                    width={'450px'}
                    destroyOnClose={true}
                >
                    <EventListDetail selectedEvent={selectedEventDetail} />
                </Drawer>
            </div>
        </>
    );
};

export default RegisteredEventsListTable;
