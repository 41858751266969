import React, { useState } from 'react';
import { Table, Button, Drawer, Dropdown, Space } from 'antd';
import { EditOutlined, EyeOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons';
import EventListDetail from './eventListDetail';
import { useNavigate } from 'react-router-dom';

const EventsListTable = ({
    isLoading,
    eventsList,
    fetchEvents,
    defaultCurrent,
    setDefaultCurrent,
    setInitialValues,
    setOpenAddEventDrawer,
    handleAddEventDrawer,
}) => {
    const navigate = useNavigate();

    const [openDetailEventDrawer, setOpenDetailEventDrawer] = useState(false);
    const [selectedEventDetail, setSelectedEventDetail] = useState(null);

    const showWresterlerDetails = (selectedRecord) => {
        setSelectedEventDetail(selectedRecord);
        setOpenDetailEventDrawer(true);
    };

    const hideWresterlerDetails = () => {
        setSelectedEventDetail(null);
        setOpenDetailEventDrawer(false);
    };

    const openEditModal = (selectedRecord) => {
        setInitialValues(selectedRecord);
        setOpenAddEventDrawer(true);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (value, record) => {
                return record?.event.name;
            },
        },
        {
            title: 'Address',
            dataIndex: 'location',
            key: 'location',
            render: (value, record) => {
                return record?.event.location;
            },
        },
        {
            title: 'Weigh Ins Start Time',
            dataIndex: 'startTimeWeighIn',
            key: 'startTimeWeighIn',
            render: (value, record) => {
                return record?.event.startTimeWeighIn;
            },
        },
        {
            title: 'Wrestling Start Time',
            dataIndex: 'startTimeBegin',
            key: 'startTimeBegin',
            render: (value, record) => {
                return record?.event.startTimeBegin;
            },
        },
        {
            title: 'Fee',
            dataIndex: 'fee',
            key: 'fee',
            render: (value, record) => {
                return <>$ {record?.event.fee}</>;
            },
        },
        {
            title: 'Is private',
            dataIndex: 'isPrivate',
            key: 'isPrivate',
            render: (value, record) => {
                return <>{record?.event.isPrivate ? 'Yes' : 'No'}</>;
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (value, record) => {
                return (
                    <Dropdown menu={renderActionMenu(record)} trigger={['click']}>
                        <Button type="default">
                            <Space>
                                Actions
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    const renderActionMenu = (record) => {
        let items = [
            {
                label: (
                    <Button
                        type="link"
                        className="p-0 d-flex gap-2 align-items-center"
                        onClick={() => {
                            navigate(`/events/event/${record?.id}`);
                        }}
                    >
                        <EyeOutlined size={18} />
                        View Details
                    </Button>
                ),
                key: '0',
            },
            {
                label: (
                    <Button
                        type="link"
                        className="p-0 d-flex gap-2 align-items-center"
                        onClick={() => openEditModal(record?.event)}
                    >
                        <EditOutlined size={18} />
                        Edit Event{' '}
                    </Button>
                ),
                key: '1',
            },
        ];

        return { items };
    };

    return (
        <>
            <div className="table-wrapper mt-4 w-100">
                <div className="d-flex align-items-center justify-content-between">
                    <h3>My Events</h3>
                    <Button
                        onClick={() => handleAddEventDrawer()}
                        type="primary"
                        className="btn-primary rounded-pill d-flex align-items-center"
                    >
                        <PlusOutlined />
                        Create event
                    </Button>
                </div>

                <Table
                    dataSource={eventsList?.data}
                    columns={columns}
                    rowKey="id"
                    scroll={{ y: 'calc(100vh - 100px)' }}
                    loading={isLoading}
                    pagination={{
                        current: defaultCurrent,
                        total: eventsList?.total || 0,
                        onChange: (page, pageSize) => {
                            setDefaultCurrent(page);
                            const offset = pageSize * (page - 1);
                            fetchEvents(offset, pageSize);
                        },
                        showSizeChanger: false,
                    }}
                />
                <Drawer
                    open={openDetailEventDrawer}
                    title="Event Details"
                    placement="right"
                    onClose={() => hideWresterlerDetails()}
                    width={'450px'}
                    destroyOnClose={true}
                >
                    <EventListDetail selectedEvent={selectedEventDetail} />
                </Drawer>
            </div>
        </>
    );
};

export default EventsListTable;
