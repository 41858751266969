import pkgInfo from '../../package.json';

export const apiEndpoint = 'https://dev-api.boutstream.com/';

export const APP_ENV = import.meta.env.VITE_APP_ENV;
export const SUPABASE_URL = import.meta.env.VITE_SUPABASE_URL;
export const SUPABASE_ANON_KEY = import.meta.env.VITE_SUPABASE_ANON_KEY;

export const isProduction = APP_ENV === 'production';

export const VERSION = pkgInfo.version;

console.log(`App ENV: ${APP_ENV}`, {
    APP_ENV,
    isProduction,
    NODE_ENV: process.env.NODE_ENV,
    SUPABASE_URL,
    SUPABASE_ANON_KEY,
});
