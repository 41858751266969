import { getSession } from './supabase/supabase-utils';
import invariant from 'tiny-invariant';
import { breadcrumb, log } from '@utils/logging';
import { InviteUserBody, TeamMember } from '@type/db-types';
import { API_URL } from './supabase/supabase-config';
import { toast } from 'react-toastify';

export const inviteUserToTeam = async (invite: InviteUserBody): Promise<TeamMember | null> => {
    const session = await getSession();
    invariant(session, 'User must be signed in to invite users');

    breadcrumb('inviteUserToTeam', invite);

    return toast
        .promise(
            fetch(`${API_URL}/user/invite`, {
                method: 'POST',
                body: JSON.stringify(invite),
                headers: {
                    Authorization: `Bearer ${session.access_token}`,
                    'Content-Type': 'application/json',
                },
            }).then((response) => response.json() as Promise<TeamMember>),
            {
                pending: 'Sending invite...',
                success: 'Profile Saved!',
                error: 'Error saving profile',
            },
        )
        .catch((error) => {
            log(`[inviteUserToTeam]: ${error.message}`);
            toast('Error inviting user', { type: 'error' });
            return null;
        });
};
