import React, { useState, useEffect } from 'react';
import Header from '../../components/layout/header';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectRegisterEventList,
    selectRegisterEventLoading,
    fetchRegisterEventsForUser,
    resetRegisterEvents,
} from './eventsSlice';
import useAuth from '../../hooks/useAuth';
import RegisteredEventsListTable from './registeredEventsListTable';
import './events.css';

const RegisteredEvents = () => {
    const dispatch = useDispatch();
    const [defaultCurrent, setDefaultCurrent] = useState(1);

    const eventsList = useSelector(selectRegisterEventList);
    const fetchEventsLoading = useSelector(selectRegisterEventLoading);
    const { authUser } = useAuth();

    useEffect(() => {
        fetchEvents(0, 10);

        return () => {
            dispatch(resetRegisterEvents());
        };
    }, []);

    const fetchEvents = (offset, limit) => {
        const userId = authUser.id;
        dispatch(fetchRegisterEventsForUser({ offset, limit, userId }));
    };

    return (
        <>
            <Header pageTitle={'My Registered Events'} />
            <div className="main-container-inner p-4">
                <RegisteredEventsListTable
                    isLoading={fetchEventsLoading}
                    eventsList={eventsList}
                    fetchEvents={fetchEvents}
                    defaultCurrent={defaultCurrent}
                    setDefaultCurrent={setDefaultCurrent}
                />
            </div>
        </>
    );
};

export default RegisteredEvents;
