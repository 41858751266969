import React from 'react';
import { Outlet } from 'react-router-dom';
import './layout.css';

const AuthLayout = ({ children }) => {
    return (
        <>
            {/* <div className="auth-main-content">
                <div className="auth-content"> */}
            {children}
            <Outlet />
            {/* </div>
            </div> */}
        </>
    );
};

export default AuthLayout;
