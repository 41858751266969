import { Session } from '@supabase/auth-js';
import { supabase } from './supabase-config';

let CURRENT_SESSION: Session | null = null;

export const getCurrentUser_synchronous = () => CURRENT_SESSION?.user;

export const getSignedInUser = async () => {
    const sessionResponse = await supabase.auth.getSession();

    // cache most recent response for synchronous access in logging calls
    CURRENT_SESSION = sessionResponse.data.session;

    if (!sessionResponse) {
        return null;
    }

    return sessionResponse.data.session?.user;
};

export const getSession = async (): Promise<Session | null> => {
    const sessionResponse = await supabase.auth.getSession();

    // cache most recent response for synchronous access in logging calls
    CURRENT_SESSION = sessionResponse.data.session;

    if (!sessionResponse) {
        return null;
    }

    return sessionResponse.data.session;
};
