import React from 'react';
import './globalComponent.css';
const MessageBox = ({ icon, title, description, button }) => {
    return (
        <div className="message-box">
            <div className="message-icon">{icon ? icon : ''}</div>
            <div className="message-content">
                <h3>{title ? title : ''}</h3>
                <p>{description ? description : ''}</p>
            </div>
            {button ? <div className="message-button text-center"> {button}</div> : ''}
        </div>
    );
};
export default MessageBox;
