import { Button, Form, Input } from 'antd';
import { useTeams } from '@providers/TeamProvider';
import { EditUserForm, InviteUserBody, STATUS, TEAM_ROLE, TeamMember } from '@type/db-types';
import invariant from 'tiny-invariant';
import { useMemo, useState } from 'react';
import { inviteUserToTeam } from '@modules/api';

interface Props {
    onSuccess: () => void;
    editingUser?: TeamMember | boolean | undefined;
}
// FIXME: unify with other user invite forms and switch to formik (`AddCoachesForm` and `AddWrestlerForm`)
const AddCoachesForm = ({ editingUser, onSuccess }: Props) => {
    const [loading, setLoading] = useState(false);
    const isExistingUser = !!(editingUser as TeamMember)?.id;
    const initialValues = useMemo(
        () => (isExistingUser ? (editingUser as TeamMember) : undefined),
        [editingUser, isExistingUser],
    );
    const { loading: teamsLoading, selectedTeam } = useTeams();

    const onFinish = (values: EditUserForm) => {
        invariant(selectedTeam, 'Team must be selected to add a coach');
        setLoading(true);

        const invite: InviteUserBody = {
            ...values,
            role: TEAM_ROLE.COACH,
            teamId: selectedTeam.id,
            teamStatus: STATUS.INVITED,
        };
        if (isExistingUser) {
            window.alert('Update coach info not implemented');
            setLoading(false);
            return;
        }

        inviteUserToTeam(invite)
            .then((result) => {
                if (result?.userId) {
                    console.log('User invited successfully', result);
                    onSuccess();
                } else {
                    console.error('Error inviting user', result);
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <div className="form-add-coach-container">
            <Form
                name="addCoachesForm"
                onFinish={onFinish}
                autoComplete="off"
                layout={'vertical'}
                initialValues={initialValues}
            >
                <div className="row">
                    <div className="col-md-6">
                        <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your first name!',
                                },
                            ]}
                        >
                            <Input size="large" placeholder="Enter First Name" />
                        </Form.Item>
                    </div>

                    <div className="col-md-6">
                        <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Last name!',
                                },
                            ]}
                        >
                            <Input size="large" placeholder="Enter Last Name" />
                        </Form.Item>
                    </div>

                    <div className="col-12">
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email',
                                },
                                {
                                    type: 'email',
                                    message: 'The input is not valid email',
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder="Enter email"
                                disabled={isExistingUser}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className="col-12  mt-3">
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="w-100 btn-primary rounded-pill"
                            loading={loading || teamsLoading}
                        >
                            {isExistingUser ? 'Update Coach info' : 'Add coach'}
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default AddCoachesForm;
