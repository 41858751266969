import React, { useState } from 'react';
import MainLogo from '../../assets/images/black-logo.svg';
import AddWrestlerForm from '../Wrestlers/AddWrestlerForm';
import MessageBox from '../../components/globalComponent/messageBox';
import { CheckCircleFilled } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { stripeService } from '../../api/services/stripe.services';

const RegisterWrestler = () => {
    const { id } = useParams();
    const [isSuccess, setIsSuccess] = useState(false);
    const navigate = useNavigate();

    // const renderTryAgainButton = () => {
    //   return (
    //     <Button type='default' onClick={() => setIsSuccess('default')} className='rounded-pill px-5 m-auto' size='large'>
    //       Try Again
    //     </Button>
    //   )
    // }

    const handleNavigate = () => {
        navigate(`/auth`);
    };

    const renderTryAgainButton = () => {
        return (
            <Button
                type="primary"
                onClick={() => handleNavigate()}
                className="rounded-pill  btn-primary px-5 m-auto"
                size="large"
            >
                Sign In
            </Button>
        );
    };

    const performPayment = async (record) => {
        // setIsPayLoading(true)
        const payload = {
            lineItems: [
                {
                    price_data: {
                        currency: 'usd',
                        product_data: {
                            name: `${record.firstName} ${record.lastName} joining in ${record.school.schoolName}`,
                        },
                        unit_amount: record.joiningFee * 100,
                    },
                    quantity: 1,
                },
            ],
            payment_intent_data: {
                metadata: {
                    payment_type: 'school_joining_fee',
                    schoolId: record.schoolId,
                    userIds: record?.studentId,
                },
            },
        };

        try {
            const response = await stripeService.doStripePayment(payload);
            window.location.href = response.url;
        } catch (error) {
            console.log('error', error);
        } finally {
            // setIsPayLoading(false)
        }
    };

    return (
        <>
            <div className="logo-wrapper public-page">
                <MainLogo />
            </div>

            {!isSuccess && (
                <div className="register-wrestler-box">
                    <div className="reg-form-content">
                        <h3>Wrestler Registration</h3>
                        <p>
                            Fill out this form and click on the submit button to send this details
                            to your coach.{' '}
                        </p>
                    </div>
                    <hr />
                    <AddWrestlerForm
                        schoolId={id}
                        registerWrestlerItSelf={true}
                        callBack={(payload) => {
                            console.log('payload', payload);
                            if (payload.joiningFee === 0) {
                                setIsSuccess(true);
                                return;
                            }
                            performPayment(payload);
                        }}
                    />
                </div>
            )}

            {isSuccess && (
                <>
                    <MessageBox
                        title={'Wrestler Registration Successfully Completed'}
                        description={`If you've already provided your email, please check your inbox 
        for further instructions on creating your password. Alternatively, reach out to your
         coach for additional assistance. Feel free to close this window.`}
                        button={renderTryAgainButton()}
                        icon={<CheckCircleFilled style={{ fontSize: '70px', color: '#52C41A' }} />}
                    ></MessageBox>
                </>
            )}

            {/* {formState === "fail" &&
        <MessageBox
          title={"Wrestler Registration not Completed"}
          button={renderTryAgainButton()}
          description={`Sorry, your registration is not completed, Please try again after sometime`}
          icon={<CloseCircleFilled style={{ fontSize: '70px', color: '#E43121' }}
          />}
        />
      } */}
        </>
    );
};

export default RegisterWrestler;
