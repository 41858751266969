import dayjs from 'dayjs';

export const calculateAge = (dob) => {
    var today = new Date();
    var birthDate = new Date(dob);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
    }
    return age_now;
};

export const hasDatePassed = (dateString) => {
    const givenDate = dayjs(dateString);
    const currentDate = dayjs();

    return givenDate.isBefore(currentDate, 'day');
};
