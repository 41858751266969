import React from 'react';
import NotFound from '../../assets/images/404.svg';
import { Link } from 'react-router-dom';

function Page404() {
    return (
        <div className="not-found-page">
            <div className="not-found-page-inner">
                <div className="not-found-page-img">
                    <NotFound />
                </div>
                <div className="not-found-page-content">
                    <h2>Page not found</h2>
                    <p>We can’t find the page that you are looking for... !</p>
                    <Link to="/" className="btn-primary">
                        Go Home
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Page404;
