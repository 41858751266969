import React, { useState, useEffect } from 'react';
import Header from '../../components/layout/header';
import './myEarnings.css';
import { Button, Table } from 'antd';
// import { useSelector } from "react-redux";
// import { selectLoginDetail } from "../auth/authSlice";
// import { toast } from "react-toastify";

const MyEarnings = () => {
    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'Date',
            render: (value, record) => {
                return <>{record?.date}</>;
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },

        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
    ];
    return (
        <>
            <Header pageTitle={'My Earnings'} />
            <div className="main-container-inner p-4">
                <div className="balance-wrapper">
                    <h5>
                        Earnings available now: <span className="balance">$50</span>
                    </h5>
                    <div className="withdraw-wrapper">
                        <Button className="withdaw-btn btn-primary  rounded-pill" type="primary">
                            Withdraw
                        </Button>
                    </div>
                </div>

                <div className="table-wrapper mt-4 w-100">
                    <div className="d-flex align-items-center justify-content-between">
                        <h3>Transaction history</h3>
                    </div>
                    <Table
                        dataSource={data}
                        columns={columns}
                        rowKey="id"
                        scroll={{ y: 'calc(100vh - 100px)' }}
                        // loading={isLoading}
                        // pagination={{
                        //   current: defaultCurrent,
                        //   total: coachesList?.total || 0,
                        //   onChange: (page, pageSize) => {
                        //     setDefaultCurrent(page);
                        //     const offset = pageSize * (page - 1);
                        //     fetchCoaches(offset, pageSize);
                        //   },
                        //   showSizeChanger: false,
                        // }}
                    />
                </div>
            </div>
        </>
    );
};

export default MyEarnings;

const data = [
    {
        date: 'Sep 6, 2024',
        type: 'Withdrawal',
        description: 'XYZ Event joining fee',
        amount: '$40.00',
    },
    {
        date: 'Sep 6, 2024',
        type: 'Withdrawal',
        description: 'XYZ Event joining fee',
        amount: '$40.00',
    },
    {
        date: 'Sep 6, 2024',
        type: 'Withdrawal',
        description: 'XYZ Event joining fee',
        amount: '$40.00',
    },
    {
        date: 'Sep 6, 2024',
        type: 'Withdrawal',
        description: 'XYZ Event joining fee',
        amount: '$40.00',
    },
    {
        date: 'Sep 6, 2024',
        type: 'Withdrawal',
        description: 'XYZ Event joining fee',
        amount: '$40.00',
    },
    {
        date: 'Sep 6, 2024',
        type: 'Withdrawal',
        description: 'XYZ Event joining fee',
        amount: '$40.00',
    },
    {
        date: 'Sep 6, 2024',
        type: 'Withdrawal',
        description: 'XYZ Event joining fee',
        amount: '$40.00',
    },
    {
        date: 'Sep 6, 2024',
        type: 'Withdrawal',
        description: 'XYZ Event joining fee',
        amount: '$40.00',
    },
    {
        date: 'Sep 6, 2024',
        type: 'Withdrawal',
        description: 'XYZ Event joining fee',
        amount: '$40.00',
    },
    {
        date: 'Sep 6, 2024',
        type: 'Withdrawal',
        description: 'XYZ Event joining fee',
        amount: '$40.00',
    },

    {
        date: 'Sep 6, 2024',
        type: 'Withdrawal',
        description: 'XYZ Event joining fee',
        amount: '$40.00',
    },
];
