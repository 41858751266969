import { createTheme } from '@mui/material';
import { amber, grey, deepOrange } from '@mui/material/colors';
// import SlideUpTransition from '@atoms/SlideUpTransition';

export const PALLETTE = {
    primary: { ...deepOrange, 500: '#ef4d22' },
    secondary: grey,
    warning: amber,
};

export const mainWhite = '#fff';
export const secondaryGray = '#dedede';
const mainDark = '#222';
export const secondaryDark = '#333';
export const mainGray = '#a5a5a5';
export const lighterGray = '#cfcfcf';
export const grayBackground = 'whitesmoke';
export const mainOrange = '#ef4d22';
export const darkOrange = '#ce370d';

export default createTheme({
    palette: PALLETTE,
    components: {
        // MuiAppBar: {
        //     styleOverrides: {
        //         root: {
        //             color: 'white',
        //             backgroundColor: mainOrange,
        //             display: 'flex',
        //             flexDirection: 'row',
        //             justifyContent: 'space-between',
        //             alignItems: 'center',
        //             padding: '0px 12px 0px 16px',
        //         },
        //     },
        // },
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    color: '#333',
                    backgroundColor: 'transparent',
                },
            },
        },
        // MuiButton: {
        //     styleOverrides: {
        //         root: {
        //             padding: '5px 15px',
        //             zIndex: 1,
        //             fontSize: '1rem',
        //         },
        //         text: {
        //             color: mainOrange,
        //             '&:hover': {
        //                 color: PALLETTE.secondary[900],
        //             },
        //         },
        //         textSecondary: {
        //             color: secondaryDark,
        //             textTransform: 'none',
        //             '&:hover': {
        //                 borderColor: '#111',
        //                 color: '#111',
        //                 backgroundColor: 'rgba(239,77,34,0.1)',
        //             },
        //         },
        //         textInfo: {
        //             color: 'white',
        //             '&:hover': {
        //                 color: PALLETTE.secondary[100],
        //                 backgroundColor: darkOrange,
        //             },
        //         },
        //         outlined: {
        //             backgroundColor: mainWhite,
        //             color: PALLETTE.secondary[800],
        //             borderColor: PALLETTE.secondary[800],
        //             '&:hover': {
        //                 borderColor: '#111',
        //                 color: '#111',
        //                 backgroundColor: 'rgba(239,77,34,0.3)',
        //             },
        //         },
        //         contained: {
        //             backgroundColor: mainOrange,
        //             color: mainWhite,
        //             '&:hover': {
        //                 borderColor: darkOrange,
        //                 backgroundColor: darkOrange,
        //             },
        //         },
        //         startIcon: {
        //             paddingLeft: '2px',
        //             // marginBottom: '2px',
        //         },
        //     },
        // },
        // MuiButtonBase: {
        //     styleOverrides: {
        //         root: {
        //             color: '#333',
        //             zIndex: 1,
        //             fontSize: '.75rem',
        //             '&.hover': {
        //                 color: '#111',
        //                 backgroundColor: 'rgba(239,77,34,0.3)',
        //             },
        //         },
        //     },
        // },
        // MuiDialog: { defaultProps: { TransitionComponent: SlideUpTransition } },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '12px',
                    '&>:not(:first-of-type)': {
                        marginLeft: '12px',
                    },
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                dividers: {
                    padding: '8px 12px',
                },
                root: {
                    padding: '0px 12px 0px 12px',
                },
            },
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    margin: '0px',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: '8px 12px',
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    backgroundColor: mainOrange,
                    color: mainWhite,
                    '&:hover': {
                        borderColor: darkOrange,
                        backgroundColor: darkOrange,
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                colorSecondary: {
                    color: mainDark,
                },
                root: {
                    padding: '8px',
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    cursor: 'pointer',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {},
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    padding: '16px',
                    '&.Mui-selected': {
                        backgroundColor: 'rgba(239,77,34,0.08)',
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: 'calc(.4vh + 4px) 12px',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: { backgroundColor: 'rgba(0, 0, 0, 0.8)', fontSize: '1rem' },
            },
        },
    },
});
