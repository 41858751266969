// Extract context and hook from Provider to fix Lint warning about fast refresh
import { Session, User as AuthUser } from '@supabase/supabase-js';
import { createContext, useContext } from 'react';

export interface AuthContextValues {
    authUser: AuthUser | undefined;
    loading: boolean;
    loggedIn: boolean;
    session: Session | null | undefined;
}

export const AuthContext = createContext<AuthContextValues>({
    authUser: undefined,
    loading: true,
    loggedIn: false,
    session: null,
});

// Access auth context values and functions
const useAuth = () => {
    const ctx = useContext(AuthContext);

    if (!ctx) console.error('useAuth called outside of AuthProvider Context');

    return ctx;
};

export default useAuth;
