import { Action, ColumnSortInfo } from '@type/app-types';
import { RESET_COLUMN_SORT, SET_COLUMN_SORT } from './redux-consts';

export const resetColumnSort = (): Action => {
    return { type: RESET_COLUMN_SORT };
};

export const setColumnSort = (title: string, columnSortInfo: ColumnSortInfo): Action => {
    return {
        type: SET_COLUMN_SORT,
        value: { tableType: title, columnSortInfo },
    };
};
