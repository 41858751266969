import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MTable, { Action, Column, MaterialTableProps, Options } from '@material-table/core';
import SearchIcon from '@mui/icons-material/Search';

import { TABLE_ICONS } from '@atoms/MaterialTableIcons';
import { setColumnSort } from '@store/actions/columnSortActions';
import { ColumnSortInfo } from '@type/app-types';
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import { selectColumnSort } from '@store/reducers/columnSortReducer';

interface Props<T extends object> extends MaterialTableProps<T> {
    loading?: boolean;
    onSelectionChange?: (items: T[]) => void;
    columnSortInfo?: ColumnSortInfo;
    tableId?: string;
}

type TableColumnData = {
    tableData: {
        // looks like columnId may be a number in later versions, but in x.x.35 it's a string
        id: number;
    };
};

export const materialTableHeight = ((window.innerHeight - 165) / window.innerHeight) * 100;

export default function MaterialTable<T extends object>({
    loading,
    options,
    onSelectionChange,
    title,
    columns,
    tableId,
    ...rest
}: Props<T>) {
    const tableOptions = useMemo(
        (): Options<T> => ({
            emptyRowsWhenPaging: false,
            columnsButton: true,
            filtering: true,
            maxBodyHeight: `${materialTableHeight}vh`,
            minBodyHeight: `${materialTableHeight}vh`,
            pageSizeOptions: [15, 35, 60],
            pageSize: 15,
            selection: !!onSelectionChange,
            maxColumnSort: 1,
            thirdSortClick: false,
            ...(options || {}),
        }),
        [onSelectionChange, options],
    );

    const columnSortData = useSelector(selectColumnSort);
    const dispatch = useDispatch();
    const tableIdentifier = tableId || title;

    const columnsConfigWithDefaultSort = useMemo((): Column<T>[] => {
        const thisTable =
            tableIdentifier &&
            typeof tableIdentifier === 'string' &&
            columnSortData[tableIdentifier];
        if (thisTable) {
            const { fieldName, direction } = thisTable;
            const sortedColumn = columns.find(({ field }) => field === fieldName);
            if (sortedColumn) {
                columns.forEach((row) => {
                    row.defaultSort && delete row.defaultSort;
                });
                sortedColumn.defaultSort = direction;
            }
        }

        columns.forEach((column) => {
            column.filterComponent = CustomFilterComponent;
        });

        return columns;
    }, [columnSortData, columns, tableIdentifier]);

    const onOrderChange = (orderBy: number, orderDirection: 'desc' | 'asc') => {
        if (typeof tableIdentifier === 'string') {
            const fieldName = columns[orderBy].field as string;
            const columnSort: ColumnSortInfo = {
                fieldName,
                direction: orderDirection,
            };
            dispatch(setColumnSort(tableIdentifier, columnSort));
        }
    };

    return (
        <MTable
            icons={TABLE_ICONS}
            isLoading={loading}
            options={tableOptions}
            onSelectionChange={onSelectionChange}
            onOrderChange={onOrderChange}
            columns={columnsConfigWithDefaultSort}
            title={title}
            {...rest}
        />
    );
}

interface FilterProps<T extends object> {
    columnDef: Column<T>;
    // looks like columnId may be a number in later versions, but in x.x.35 it's a string
    onFilterChanged: (columnId: number, value: T) => void;
}

const CustomFilterComponent = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { columnDef, onFilterChanged }: FilterProps<any>,
) => {
    return (
        <FormControl fullWidth variant="outlined">
            <OutlinedInput
                size="small"
                sx={{
                    '& .MuiInputBase-input': {
                        padding: '4px 4px 4px 0px',
                    },
                    paddingLeft: '6px',
                }}
                onChange={(e) => {
                    const columnId = (columnDef as Column<object> & TableColumnData)?.tableData?.id;
                    // accept '0' index
                    if (columnId !== undefined && columnId !== null) {
                        onFilterChanged(
                            // looks like columnId may be a number in later versions, but in x.x.35 it's a string
                            columnId,
                            e.target.value,
                        );
                    }
                }}
                startAdornment={
                    <InputAdornment position="start" sx={{ marginRight: '4px' }}>
                        <SearchIcon fontSize="small" />
                    </InputAdornment>
                }
            />
        </FormControl>
    );
};

export type TableActions<Type extends object> =
    | (
          | Action<Type>
          | ((rowData: Type) => Action<Type>)
          | {
                action: (rowData: Type) => Action<Type>;
                position: string;
            }
      )[]
    | undefined;
