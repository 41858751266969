import React, { useMemo, useRef, useState } from 'react';
import { Drawer } from 'antd';
import AddCoachesForm from './addCoachesForm';
import './coaches.css';
import { useTeams } from '@providers/TeamProvider';
import MaterialTable, { TableActions } from '@components/molecules/MaterialTable';
import { Column, Options } from '@material-table/core';
import { CopyToClipboardColumn } from '@atoms/CopyToClipboardColumn';
import { readableDateFromDate } from '@utils/date-utils';
import { RenderEditButton } from '@atoms/RenderEditButton';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import FAB from '@atoms/FAB';
import { TeamMember } from '@type/db-types';

const Coaches = () => {
    const tableRef: React.MutableRefObject<undefined | unknown> = useRef();
    const { coaches, refreshMembers } = useTeams();
    const [editingUser, setEditingUser] = useState<TeamMember | boolean | undefined>(undefined);

    const tableColumns = useMemo(() => {
        return geCoachesTableColumns((data: TeamMember) => setEditingUser(data));
    }, []);

    const successCallBack = () => {
        setEditingUser(undefined);

        // takes a minute for the updated info to trickle through
        // TODO: eventually we should hook this up to a watcher...
        setTimeout(() => refreshMembers(), 1000);
    };

    const actions: TableActions<TeamMember> = useMemo(
        () => [
            {
                icon: PersonAddAltRoundedIcon,
                tooltip: 'Add Coach',
                isFreeAction: true,
                onClick: () => setEditingUser(true),
            },
        ],

        [],
    );

    return (
        <>
            <div className="main-container-inner p-4">
                <MaterialTable<TeamMember>
                    tableRef={tableRef}
                    actions={actions}
                    columns={tableColumns}
                    data={coaches || []}
                    localization={{
                        toolbar: {
                            nRowsSelected: (number) =>
                                `${number} coach${number === 1 ? '' : 's'} selected`,
                        },
                    }}
                    loading={coaches === undefined}
                    options={TABLE_OPTIONS}
                    // onSelectionChange={onSelectionChange}
                    // onRowClick={(e, rowData) => {
                    //     setSelecteduser(rowData);
                    // }}
                    tableId="BrandBadges"
                    title="Coaches"
                />
            </div>
            <Drawer
                title={editingUser ? 'Edit Coach' : 'Add coach'}
                placement="right"
                onClose={() => setEditingUser(undefined)}
                open={editingUser !== undefined}
                width={'550px'}
                destroyOnClose={true}
            >
                <AddCoachesForm editingUser={editingUser} onSuccess={successCallBack} />
            </Drawer>
            <FAB onClick={() => setEditingUser(true)} toolTip="Add coach" />
        </>
    );
};

export default Coaches;

const TABLE_OPTIONS: Options<TeamMember> = {
    actionsColumnIndex: -1,
    // maxBodyHeight: `${materialTableHeight + 3}vh`,
    // minBodyHeight: `${materialTableHeight + 3}vh`,
};

const geCoachesTableColumns = (onPressEdit: (data: TeamMember) => void): Column<TeamMember>[] => [
    { title: 'First Name', field: 'firstName' },
    { title: 'Last Name', field: 'lastName' },
    { title: 'Role', field: 'role' },
    { title: 'Status', field: 'teamStatus' },
    {
        title: 'Email',
        field: 'email',
        render: (data) => <CopyToClipboardColumn text={data.email || ''} />,
    },
    {
        field: 'userId',
        filtering: false,
        hidden: true,
        title: 'ID',
        render: (data) => <CopyToClipboardColumn text={data.userId || ''} />,
    },
    {
        title: 'Phone',
        field: 'phone',
        render: (data) => (data.phone ? <CopyToClipboardColumn text={data.phone} /> : '...'),
    },
    {
        title: 'Created At',
        hidden: true,
        field: 'createdAt',
        render: ({ userCreatedAt }) =>
            userCreatedAt ? readableDateFromDate(userCreatedAt) : userCreatedAt,
        type: 'numeric',
    },
    ...((onPressEdit
        ? [
              {
                  title: 'Edit',
                  field: 'edit',
                  render: (data) => <RenderEditButton onPressEdit={onPressEdit} {...data} />,
              },
          ]
        : []) as Column<TeamMember>[]),
];
