import React, { useState, useEffect } from 'react';
import Header from '../../components/layout/header';
import './events.css';
import { Drawer } from 'antd';
import AddUpdateEventForm from './AddUpdateEventForm';
import EventsListTable from './eventsListTable';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectEventsList,
    selectFetchEventsLoading,
    getUserEvents,
    resetEventsSlice,
} from './eventsSlice';
import useAuth from '../../hooks/useAuth';

const ManageEvents = () => {
    const dispatch = useDispatch();
    const [openAddEventsDrawer, setOpenAddEventDrawer] = useState(false);
    const [defaultCurrent, setDefaultCurrent] = useState(1);
    const [initialValues, setInitialValues] = useState(undefined);

    const eventsList = useSelector(selectEventsList);
    const fetchEventsLoading = useSelector(selectFetchEventsLoading);
    const { authUser } = useAuth();

    useEffect(() => {
        fetchEvents(0, 10);

        return () => {
            dispatch(resetEventsSlice());
        };
    }, []);

    const fetchEvents = (offset, limit) => {
        const userId = authUser.accountId;
        dispatch(getUserEvents({ offset, limit, userId }));
    };

    const handleAddEventDrawer = () => {
        setOpenAddEventDrawer(true);
        setInitialValues(undefined);
    };

    const handleAddEventDrawerClose = () => {
        setOpenAddEventDrawer(false);
    };

    const successCallBack = () => {
        setOpenAddEventDrawer(false);
        fetchEvents(0, 10);
        setDefaultCurrent(1);
    };

    return (
        <>
            <Header pageTitle={'My Events'} />
            <div className="main-container-inner p-4">
                <EventsListTable
                    isLoading={fetchEventsLoading}
                    eventsList={eventsList}
                    fetchEvents={fetchEvents}
                    defaultCurrent={defaultCurrent}
                    setDefaultCurrent={setDefaultCurrent}
                    setInitialValues={setInitialValues}
                    setOpenAddEventDrawer={setOpenAddEventDrawer}
                    handleAddEventDrawer={handleAddEventDrawer}
                />
            </div>

            <Drawer
                title={initialValues?.id ? 'Update Event' : 'Create Event'}
                placement="right"
                onClose={handleAddEventDrawerClose}
                open={openAddEventsDrawer}
                width={'550px'}
                destroyOnClose={true}
            >
                <AddUpdateEventForm callBack={successCallBack} initialValues={initialValues} />
            </Drawer>
        </>
    );
};

export default ManageEvents;
