import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { eventService } from '../../api/services/event.service';

export const eventsInitialState = {
    fetchEventsLoading: false,
    eventsList: [],
    addUpdateEvent: false,
    fetchAllPublicEventsLoading: false,
    eventsPublicList: [],
    fetchEventDetailLoading: false,
    eventDetails: null,
    joinEventLoading: false,
    joinBulkEventLoading: false,
    registerEventsList: [],
    fetchRegisterEventsListLoading: false,
    fetchWrestlersForEventLoading: false,
    wrestlersForEvent: null,
};

const eventsSlice = createSlice({
    name: 'events',
    initialState: eventsInitialState,
    reducers: {
        resetEventsSlice: (state) => {
            return {
                ...state,
                fetchEventsLoading: false,
                addUpdateEvent: false,
                eventsList: [],
            };
        },
        resetPublicEventsSlice: (state) => {
            return {
                ...state,
                fetchAllPublicEventsLoading: false,
                eventsPublicList: [],
            };
        },
        resetEventDetails: (state) => {
            return {
                ...state,
                fetchEventDetailLoading: false,
                eventDetails: null,
            };
        },
        resetRegisterEvents: (state) => {
            return {
                ...state,
                fetchRegisterEventsListLoading: false,
                registerEventsList: [],
            };
        },
        resetWrestlersForEvent: (state) => {
            return {
                ...state,
                fetchWrestlersForEventLoading: false,
                wrestlersForEvent: [],
            };
        },
    },

    extraReducers(builder) {
        builder
            .addCase(getUserEvents.pending, (state) => {
                return {
                    ...state,
                    fetchEventsLoading: true,
                };
            })
            .addCase(getUserEvents.fulfilled, (state, action) => {
                return {
                    ...state,
                    eventsList: action.payload,
                    fetchEventsLoading: false,
                };
            })
            .addCase(getUserEvents.rejected, (state, action) => {
                toast.error(action.error.message);
                return {
                    ...state,
                    fetchEventsLoading: false,
                };
            })
            .addCase(getAllPublicEvents.pending, (state) => {
                return {
                    ...state,
                    fetchAllPublicEventsLoading: true,
                };
            })
            .addCase(getAllPublicEvents.fulfilled, (state, action) => {
                return {
                    ...state,
                    eventsPublicList: action.payload,
                    fetchAllPublicEventsLoading: false,
                };
            })
            .addCase(getAllPublicEvents.rejected, (state, action) => {
                toast.error(action.error.message);
                return {
                    ...state,
                    fetchAllPublicEventsLoading: false,
                };
            })
            .addCase(getWrestlersForEvents.pending, (state) => {
                return {
                    ...state,
                    fetchWrestlersForEventLoading: true,
                };
            })
            .addCase(getWrestlersForEvents.fulfilled, (state, action) => {
                return {
                    ...state,
                    wrestlersForEvent: action.payload,
                    fetchWrestlersForEventLoading: false,
                };
            })
            .addCase(getWrestlersForEvents.rejected, (state, action) => {
                toast.error(action.error.message);
                return {
                    ...state,
                    fetchWrestlersForEventLoading: false,
                };
            })
            .addCase(getEventDetails.pending, (state) => {
                return {
                    ...state,
                    fetchEventDetailLoading: true,
                };
            })
            .addCase(getEventDetails.fulfilled, (state, action) => {
                return {
                    ...state,
                    eventDetails: action.payload?.data,
                    fetchEventDetailLoading: false,
                };
            })
            .addCase(getEventDetails.rejected, (state, action) => {
                toast.error(action.error.message);
                return {
                    ...state,
                    fetchEventDetailLoading: false,
                };
            })
            .addCase(addUpdateEvent.pending, (state) => {
                return {
                    ...state,
                    addUpdateEvent: true,
                };
            })
            .addCase(addUpdateEvent.fulfilled, (state, action) => {
                toast.success(action?.payload?.message || 'New event is successfully created');
                return {
                    ...state,
                    addUpdateEvent: false,
                };
            })
            .addCase(addUpdateEvent.rejected, (state, action) => {
                toast.error(action.error.message);
                return {
                    ...state,
                    addUpdateEvent: false,
                };
            })
            .addCase(joinEvent.pending, (state) => {
                return {
                    ...state,
                    joinEventLoading: true,
                };
            })
            .addCase(joinEvent.fulfilled, (state, action) => {
                toast.success(action?.payload?.message || 'Students added successfully');
                return {
                    ...state,
                    joinEventLoading: false,
                };
            })
            .addCase(joinEvent.rejected, (state, action) => {
                toast.error(action.error.message);
                return {
                    ...state,
                    joinEventLoading: false,
                };
            })
            .addCase(joinBulkEvent.pending, (state) => {
                return {
                    ...state,
                    joinBulkEventLoading: true,
                };
            })
            .addCase(joinBulkEvent.fulfilled, (state, action) => {
                return {
                    ...state,
                    joinBulkEventLoading: false,
                };
            })
            .addCase(joinBulkEvent.rejected, (state, action) => {
                toast.error(action.error.message);
                return {
                    ...state,
                    joinBulkEventLoading: false,
                };
            })

            .addCase(fetchRegisterEventsForUser.pending, (state) => {
                return {
                    ...state,
                    fetchRegisterEventsListLoading: true,
                };
            })
            .addCase(fetchRegisterEventsForUser.fulfilled, (state, action) => {
                return {
                    ...state,
                    registerEventsList: action.payload,
                    fetchRegisterEventsListLoading: false,
                };
            })
            .addCase(fetchRegisterEventsForUser.rejected, (state, action) => {
                toast.error(action.error.message);
                return {
                    ...state,
                    fetchRegisterEventsListLoading: false,
                };
            });
    },
});

export const getUserEvents = createAsyncThunk('auth/getUserEvents', async (args) => {
    const { offset, limit, userId } = args;
    const query = `userId=${userId}&offset=${offset}&limit=${limit}`;
    return eventService.fetchEventsList(query);
});

export const getAllPublicEvents = createAsyncThunk('auth/getAllPublicEvents', async (args) => {
    const { offset, limit, userId } = args;
    const query = `offset=${offset}&limit=${limit}`;
    const registerEventsIds = await eventService.fetchRegisterEventIdsForUser(userId);
    const publicEvents = await eventService.fetchAllPublicEvents(query);
    const fetchIds = registerEventsIds.data.map((item) => item.eventId);
    const combineEvents = {
        publiceEvents: publicEvents,
        eventsIds: fetchIds,
    };
    return combineEvents;
});

export const getWrestlersForEvents = createAsyncThunk(
    'auth/getWrestlersForEvents',
    async (args) => {
        const { offset, limit, eventId, schoolId } = args;
        const query = `offset=${offset}&limit=${limit}`;
        const registerUserIds = await eventService.fetchRegisterUserIdsForEvent(eventId, schoolId);
        const fetchWrestlers = [];
        // const fetchWrestlers = await wrestlerService.fetchWrestlersList(query);
        const fetchIds = registerUserIds.data.map((item) => item.userId);

        const combineWrestlers = {
            wrestlersData: fetchWrestlers,
            userIds: fetchIds,
        };
        return combineWrestlers;
    },
);

export const getEventDetails = createAsyncThunk('auth/getEventDetails', async (eventId) => {
    return eventService.fetchEventDetails(eventId);
});

export const joinEvent = createAsyncThunk('auth/joinEvent', async (payload) => {
    return eventService.joinEvent(payload);
});

export const joinBulkEvent = createAsyncThunk('auth/joinBulkEvent', async (payload) => {
    return eventService.joinBulkEventEvent(payload);
});

export const addUpdateEvent = createAsyncThunk('auth/addUpdateEvent', async (payload) => {
    return payload?.id ? eventService.updateEvent(payload) : eventService.createEvent(payload);
});

export const fetchRegisterEventsForUser = createAsyncThunk(
    'auth/fetchRegisterEventsForUser',
    async (args) => {
        const { offset, limit, userId } = args;
        const query = `userId=${userId}&offset=${offset}&limit=${limit}`;
        return eventService.getRegisterEventsForUser(query);
    },
);

export const {
    resetEventsSlice,
    resetPublicEventsSlice,
    resetEventDetails,
    resetRegisterEvents,
    resetWrestlersForEvent,
} = eventsSlice.actions;

export const selectFetchEventsLoading = (state) => {
    return state.events.fetchEventsLoading;
};

export const selectEventsList = (state) => {
    return state.events.eventsList;
};

export const selectFetchPublicEventsLoading = (state) => {
    return state.events.fetchAllPublicEventsLoading;
};

export const selectPublicEventsList = (state) => {
    return state.events.eventsPublicList;
};

export const selectAddUpdateEvent = (state) => {
    return state.events.addUpdateEvent;
};

export const selectEventDetailsLoading = (state) => {
    return state.events.fetchEventDetailLoading;
};

export const selectEventDetails = (state) => {
    return state.events.eventDetails;
};

export const selectJoinEventLoading = (state) => {
    return state.events.joinEventLoading;
};

export const selectJoinBulkEventLoading = (state) => {
    return state.events.joinBulkEventLoading;
};

export const selectRegisterEventList = (state) => {
    return state.events.registerEventsList;
};

export const selectRegisterEventLoading = (state) => {
    return state.events.fetchRegisterEventsListLoading;
};

export const selectWrestlersForEventLoading = (state) => {
    return state.events.fetchWrestlersForEventLoading;
};

export const selectWrestlersForEvent = (state) => {
    return state.events.wrestlersForEvent;
};

export default eventsSlice.reducer;
