import { Navigate } from 'react-router-dom';

// Guards
import AuthGuard from './components/guards/authGuard';
// import RolesGuard from './components/guards/rolesGuard';
import DashboardLayout from './components/layout/DashboardLayout';
// @ts-expect-error switch to TS later...
import AuthLayout from './components/layout/authLayout';

import Auth from './pages/auth/auth';

// Typescript routes
import Dashboard from './pages/Dashboard/dashboard';
import Wrestlers from './pages/Wrestlers/Wrestlers';
import Coaches from './pages/Coaches/Coaches';
import Settings from './pages/Settings/settings';

// @ts-expect-error switch to TS later...
import Page404 from './pages/auth/page404';
// @ts-expect-error switch to TS later...
import RegisterWrestler from './pages/auth/registerWrestler';
// @ts-expect-error switch to TS later...
import Events from './pages/Events/events';
// @ts-expect-error switch to TS later...
import ManageEvents from './pages/Events/manageEvents';
// @ts-expect-error switch to TS later...
import EventsDetails from './pages/Events/eventDetail';
// @ts-expect-error switch to TS later...
import RegisteredEvents from './pages/Events/registeredEvents';
// @ts-expect-error switch to TS later...
import PaymentSuccess from './pages/Payment/paymentSuccess';
// @ts-expect-error switch to TS later...
import PaymentFail from './pages/Payment/paymentFail';
// @ts-expect-error switch to TS later...
import MyEventDetail from './pages/Events/myEventDetail';
// @ts-expect-error switch to TS later...
import MyEarnings from './pages/MyEarnings/MyEarnings';

const routes = [
    {
        path: '/',
        element: <Navigate to="/dashboard" replace={true} />,
        exact: true,
    },
    {
        path: 'events-public',
        element: <DashboardLayout />,
        children: [
            {
                path: '',
                element: <Events />,
            },
            {
                path: 'event/:id',
                element: <MyEventDetail />,
            },
            {
                path: 'event-details/:id',
                element: <EventsDetails />,
            },
        ],
    },

    {
        path: 'dashboard',
        element: (
            <AuthGuard>
                {/* <RolesGuard roles={STUDENT}> */}
                <DashboardLayout />
                {/* </RolesGuard> */}
            </AuthGuard>
        ),
        children: [
            {
                path: '',
                element: <Dashboard />,
            },
        ],
    },
    {
        path: 'settings',
        element: (
            <AuthGuard>
                {/* <RolesGuard roles={SUPER_COACH}> */}
                <DashboardLayout />
                {/* </RolesGuard> */}
            </AuthGuard>
        ),
        children: [
            {
                path: '',
                element: <Settings />,
            },
        ],
    },
    {
        path: 'earning',
        element: (
            <AuthGuard>
                {/* <RolesGuard roles={ALL}> */}
                <DashboardLayout />
                {/* </RolesGuard> */}
            </AuthGuard>
        ),
        children: [
            {
                path: '',
                element: <MyEarnings />,
            },
        ],
    },
    {
        path: 'events',
        element: (
            <AuthGuard>
                {/* <RolesGuard roles={ALL}> */}
                <DashboardLayout />
                {/* </RolesGuard> */}
            </AuthGuard>
        ),
        children: [
            {
                path: '',
                element: <Events />,
            },
            {
                path: 'manage-events',
                element: <ManageEvents />,
            },
            {
                path: 'event/:id',
                element: <MyEventDetail />,
            },
            {
                path: 'event-details/:id',
                element: <EventsDetails />,
            },
            {
                path: 'register-events',
                element: <RegisteredEvents />,
            },
        ],
    },
    {
        path: 'wrestlers',
        element: (
            <AuthGuard>
                {/* <RolesGuard roles={[...COACH, ...SUPER_COACH]}> */}
                <DashboardLayout />
                {/* </RolesGuard> */}
            </AuthGuard>
        ),
        children: [
            {
                path: '',
                element: <Wrestlers />,
            },
        ],
    },
    {
        path: 'coaches',
        element: (
            <AuthGuard>
                {/* <RolesGuard roles={SUPER_COACH}> */}
                <DashboardLayout />
                {/* </RolesGuard> */}
            </AuthGuard>
        ),
        children: [
            {
                path: '',
                element: <Coaches />,
            },
        ],
    },
    {
        path: '',
        element: <AuthLayout />,
        children: [
            {
                path: '/auth',
                element: <Auth />,
            },
            {
                path: '/register-wrestler/:id',
                element: <RegisterWrestler />,
            },
        ],
    },
    {
        path: '/payment/success',
        element: <PaymentSuccess />,
    },
    {
        path: '/payment/cancel',
        element: <PaymentFail />,
    },
    {
        path: '*',
        element: <AuthLayout />,
        children: [
            {
                path: '*',
                element: <Page404 />,
            },
        ],
    },
];

export default routes;
