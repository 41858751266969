import React, { useEffect, useState } from 'react';
import { Badge, Button, Modal } from 'antd';
import { Calendar, MapPin, Archive, DollarSign } from 'react-feather';
import JoinEventForm from './joinEventForm';
import useAuth from '../../hooks/useAuth';
import { COACH, STUDENT, SUPER_COACH, EVENT_MANAGER } from '../../utils/rolesConfig';
import JoinBulkEventsForm from './joinBulkEventsForm';
import './events.css';
// import StripeContainer from "../../components/stripe/StripeContainer";
import RegisterWrestlersBulkEventsList from './registerWrestlersBulkEventsList';
import { hasDatePassed } from '../../utils/helpers';

const EventsCard = ({ eventDetail, moreInfoAction, selectedIds }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showBulkRegistrationModal, setShowBulkRegistrationModal] = useState(false);
    const [showBulkStudentRegisterModal, setShowBulkStudentRegisterModal] = useState(false);
    const [selectedIdsFromProp, setSelectedIdsFromProp] = useState([]);

    const { loading, session, authUser } = useAuth();
    // const paymentIntent = useSelector(selectPaymentIntent);
    // const clientSecret = paymentIntent?.client_secret;

    const role = authUser.role; // data shape & authorization logic has changed...

    useEffect(() => {
        setSelectedIdsFromProp(selectedIds);
    }, []);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const checkIfAlreadyRegister = () => {
        if (selectedIdsFromProp.includes(eventDetail?.id)) {
            return true;
        }
        return false;
    };

    return (
        <>
            <Badge.Ribbon
                text={hasDatePassed(eventDetail.date) ? 'Reg. closed' : 'Reg. open'}
                color={hasDatePassed(eventDetail.date) ? 'red' : 'green'}
            >
                <div className="event-card mb-3 h-100">
                    <div className="event-card-inner">
                        <div className="event-card-header">
                            <div className="event-name">
                                <h2>{eventDetail?.name}</h2>
                            </div>
                        </div>
                        <div className="event-card-time-locaion">
                            <p>
                                <span className="icon">
                                    <Calendar />
                                </span>{' '}
                                {eventDetail?.date}
                            </p>
                            <p>
                                <span className="icon">
                                    <MapPin />{' '}
                                </span>{' '}
                                {eventDetail?.location}
                            </p>
                            <p>
                                <span className="icon">
                                    <DollarSign />{' '}
                                </span>{' '}
                                {eventDetail?.fee} USD
                            </p>
                            <p>
                                <span className="icon">
                                    <Archive />{' '}
                                </span>{' '}
                                05/{eventDetail?.maxEntryNumber} Seats left
                            </p>
                        </div>
                        {/* <div className="event-description mt-3">
                            <p>{eventDetail?.description}</p>
                        </div> */}
                        {/* <div className="organized-by mb-2 mt-3">
              Joined by:
              <div className="avatars">
                <span className="avatar">
                  <img src="https://picsum.photos/70" alt="user_image" />
                </span>
                <span className="avatar">
                  <img src="https://picsum.photos/80" alt="user_image" />
                </span>
                <span className="avatar">
                  <img src="https://picsum.photos/90" alt="user_image" />
                </span>
                <span className="avatar">
                  <img src="https://picsum.photos/100" alt="user_image" />
                </span>

                <span className="avatar">
                  <img src="https://picsum.photos/70" alt="user_image" />
                </span>
                <span className="avatar">
                  <img src="https://picsum.photos/80" alt="user_image" />
                </span>
              </div>
              <span className="more-text"> and 23 others</span>
            </div> */}
                        {/* <div className="organized-by">
                            Organize by: <span>{eventDetail?.manager?.user?.firstName} {eventDetail?.manager?.user?.lastName}</span>
                        </div> */}
                        <div className="event-action mt-3">
                            {(role === COACH[0] || role === SUPER_COACH[0]) && (
                                <>
                                    <Button
                                        type="primary"
                                        className="w-100 btn-primary rounded-pill mb-2"
                                        onClick={() => {
                                            setShowBulkRegistrationModal(true);
                                        }}
                                        disabled={hasDatePassed(eventDetail.date)}
                                    >
                                        {hasDatePassed(eventDetail.date)
                                            ? 'Registeration Closed'
                                            : ' Register Wrestlers'}
                                    </Button>

                                    <Button
                                        type={'dashed'}
                                        className={'w-100 btn-dashed rounded-pill'}
                                        onClick={() => setShowBulkStudentRegisterModal(true)}
                                    >
                                        View registered Wrestlers
                                    </Button>
                                </>
                            )}

                            {role === STUDENT[0] && (
                                <Button
                                    type={'primary'}
                                    className={
                                        checkIfAlreadyRegister()
                                            ? 'w-100 btn-primary rounded-pill mb-2 pe-none'
                                            : 'w-100 btn-primary rounded-pill mb-2'
                                    }
                                    onClick={showModal}
                                    disabled={
                                        checkIfAlreadyRegister() || hasDatePassed(eventDetail.date)
                                    }
                                >
                                    {checkIfAlreadyRegister()
                                        ? 'Already register'
                                        : hasDatePassed(eventDetail.date)
                                          ? 'Registeration Closed'
                                          : 'Register Now'}
                                </Button>
                            )}
                            <Button
                                type={role === EVENT_MANAGER[0] ? 'primary' : 'link'}
                                className={
                                    role === EVENT_MANAGER[0]
                                        ? 'w-100 btn-primary rounded-pill'
                                        : 'w-100 btn-link'
                                }
                                onClick={moreInfoAction && moreInfoAction}
                            >
                                More information
                            </Button>
                        </div>
                    </div>
                </div>
            </Badge.Ribbon>

            <Modal
                title={
                    <span className="custom-heading">Wrestlers register to {eventDetail.name}</span>
                }
                open={showBulkStudentRegisterModal}
                width={'1200px'}
                footer={false}
                destroyOnClose={true}
                onCancel={() => setShowBulkStudentRegisterModal(false)}
            >
                <RegisterWrestlersBulkEventsList
                    eventDetail={eventDetail}
                    setIsModalOpen={setShowBulkStudentRegisterModal}
                />
            </Modal>

            <Modal
                title={
                    <span className="custom-heading">
                        Add wrestlers to {eventDetail.name} - ${eventDetail.fee}
                    </span>
                }
                open={showBulkRegistrationModal}
                width={'1200px'}
                footer={false}
                destroyOnClose={true}
                onCancel={() => setShowBulkRegistrationModal(false)}
            >
                <JoinBulkEventsForm
                    eventDetail={eventDetail}
                    setIsModalOpen={setShowBulkRegistrationModal}
                />
            </Modal>

            <Modal
                title={
                    <span>
                        Register yourself for {eventDetail?.name} - ${eventDetail?.fee}
                    </span>
                }
                open={isModalOpen}
                footer={false}
                destroyOnClose={true}
                onCancel={() => setIsModalOpen(false)}
            >
                {/* <StripeContainer clientSecret={clientSecret}> */}
                <JoinEventForm
                    eventDetail={eventDetail}
                    setIsModalOpen={setIsModalOpen}
                    setSelectedIdsFromProp={setSelectedIdsFromProp}
                    selectedIdsFromProp={selectedIdsFromProp}
                />
                {/* </StripeContainer> */}
            </Modal>
        </>
    );
};

export default EventsCard;
