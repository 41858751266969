import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authService } from '../../api/services/auth.service';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { axiosInstance } from '../../api/httpClient/httpService';

const getLoginUserDetailsFromCookie = Cookies.get('user');

export const atuthInitialState = {
    loginLoading: false,
    signUpLoading: false,
    schoolNameLoading: false,
    setupIntentLoading: false,
    setupIntent: null,
    setupIntentResult: null,
    paymentIntentLoading: false,
    paymentIntent: null,
    schoolList: [],
    loginDetail: getLoginUserDetailsFromCookie ? JSON.parse(getLoginUserDetailsFromCookie) : null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState: atuthInitialState,
    reducers: {
        resetAuthSlice: (state) => {
            return {
                ...state,
                loginLoading: false,
                signUpLoading: false,
                schoolNameLoading: false,
                setupIntentLoading: false,
                setupIntent: null,
                setupIntentResult: null,
                paymentIntentLoading: false,
                paymentIntent: null,
                schoolList: [],
                loginDetail: null,
            };
        },
        signOut: (state) => {
            storeAuthToken(null);
            Cookies.remove('user');
            window.location.reload();
            return {
                ...state,
                loginLoading: false,
                loginDetail: null,
                schoolNameLoading: false,
                schoolList: [],
                setupIntentLoading: false,
                setupIntent: null,
                setupIntentResult: null,
                paymentIntentLoading: false,
                paymentIntent: null,
                signUpLoading: false,
            };
        },
        startupLoadSession: () => {
            const token =
                getLoginUserDetailsFromCookie && JSON.parse(getLoginUserDetailsFromCookie).token;
            if (token) {
                storeAuthToken(token);
            }
        },
        setLoginDetail: (state, action) => {
            if (!action.payload.isApprovedBySuperCoach) {
                toast.info('Your account is not approved yet');
            }
            Cookies.set('user', JSON.stringify(action.payload));
            return {
                ...state,
                loginDetail: action.payload,
            };
        },
        setSetupIntentResult: (state, action) => {
            return {
                ...state,
                setupIntentResult: action.payload,
            };
        },
    },

    extraReducers(builder) {
        builder
            .addCase(getLogin.pending, (state) => {
                return {
                    ...state,
                    loginLoading: true,
                };
            })
            .addCase(getLogin.fulfilled, (state, action) => {
                Cookies.set('user', JSON.stringify(action.payload.data));
                storeAuthToken(action.payload.data.token);
                return {
                    ...state,
                    loginDetail: action.payload.data,
                    loginLoading: false,
                };
            })
            .addCase(getLogin.rejected, (state, action) => {
                toast.error(action.error.message);
                return {
                    ...state,
                    loginLoading: false,
                };
            })
            .addCase(signUp.pending, (state) => {
                return {
                    ...state,
                    signUpLoading: true,
                };
            })
            .addCase(signUp.fulfilled, (state, action) => {
                Cookies.set('user', JSON.stringify(action.payload.data));
                storeAuthToken(action.payload.data.token);
                return {
                    ...state,
                    loginDetail: action.payload.data,
                    signUpLoading: false,
                };
            })
            .addCase(signUp.rejected, (state, action) => {
                toast.error(action.error.message);
                return {
                    ...state,
                    signUpLoading: false,
                };
            })
            .addCase(fetchSchoolsName.pending, (state) => {
                return {
                    ...state,
                    schoolNameLoading: true,
                };
            })
            .addCase(fetchSchoolsName.fulfilled, (state, action) => {
                const updateSchoolObj = updateSchoolResponse(action.payload);
                return {
                    ...state,
                    schoolNameLoading: false,
                    schoolList: updateSchoolObj,
                };
            })
            .addCase(fetchSchoolsName.rejected, (state, action) => {
                toast.success(action.error.message);
                return {
                    ...state,
                    schoolNameLoading: false,
                };
            })
            .addCase(createSetupIntent.pending, (state) => {
                return {
                    ...state,
                    setupIntentLoading: true,
                };
            })
            .addCase(createSetupIntent.fulfilled, (state, action) => {
                return {
                    ...state,
                    setupIntent: action.payload,
                    setupIntentLoading: false,
                };
            })
            .addCase(createSetupIntent.rejected, (state, action) => {
                toast.error(action.error.message);
                return {
                    ...state,
                    setupIntentLoading: false,
                };
            })
            .addCase(createPaymentIntent.pending, (state) => {
                return {
                    ...state,
                    paymentIntentLoading: true,
                };
            })
            .addCase(createPaymentIntent.fulfilled, (state, action) => {
                return {
                    ...state,
                    paymentIntent: action.payload,
                    paymentIntentLoading: false,
                };
            })
            .addCase(createPaymentIntent.rejected, (state, action) => {
                toast.error(action.error.message);
                return {
                    ...state,
                    paymentIntentLoading: false,
                };
            });
    },
});

export const getLogin = createAsyncThunk('auth/getLogin', async (payload) => {
    return authService.getLoginDetails(payload);
});

export const signUp = createAsyncThunk('auth/signUp', async (payload) => {
    return authService.userSignup(payload);
});

export const fetchSchoolsName = createAsyncThunk('auth/fetchSchoolsName', async () => {
    return authService.getSchoolsName();
});

// stripe
export const createSetupIntent = createAsyncThunk('auth/createSetupIntent', async ({ payload }) => {
    return authService.createSetupIntent(payload);
});

export const createPaymentIntent = createAsyncThunk(
    'auth/createPaymentIntent',
    async ({ payload }) => {
        return authService.createPaymentIntent(payload);
    },
);

export const { resetAuthSlice, startupLoadSession, signOut, setLoginDetail, setSetupIntentResult } =
    authSlice.actions;

const storeAuthToken = (authToken) => {
    if (authToken) {
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${authToken}`;
        return;
    }
    axiosInstance.defaults.headers.common.Authorization = null;
};

const updateSchoolResponse = (schoolList) => {
    if (schoolList && schoolList.length) {
        const mapSchoolObj = schoolList.map((school) => {
            return {
                value: school.id,
                label: school.schoolName,
                schoolCity: school.schoolCity,
                schoolState: school.schoolState,
            };
        });
        return mapSchoolObj;
    }
    return [];
};

export const selectLoginDetail = (state) => {
    return state.auth.loginDetail;
};

export const selectLoginLoading = (state) => {
    return state.auth.loginLoading;
};

export const selectSignUpLoading = (state) => {
    return state.auth.signUpLoading;
};

export const selectSchoolList = (state) => {
    return state.auth.schoolList;
};

export const selectSchoolNameLoading = (state) => {
    return state.auth.schoolNameLoading;
};

export const selectSetupIntent = (state) => {
    return state.auth.setupIntent;
};

export const selectSetupIntentLoading = (state) => {
    return state.auth.setupIntentLoading;
};

export const selectPaymentIntent = (state) => {
    return state.auth.paymentIntent;
};

export const selectPaymentIntentLoading = (state) => {
    return state.auth.paymentIntentLoading;
};

export default authSlice.reducer;
