import { Outlet } from 'react-router-dom';
import './layout.css';
import SideBar from '../SideBar/SideBar';
import { Layout } from 'antd';

const DashboardLayout = ({ children }: { children?: any }) => {
    const { Content } = Layout;

    return (
        <Layout className="main-layout" hasSider>
            <SideBar />
            <Content>
                <div className="main-layout-inner w-100">
                    <div className="main-content">
                        {children}
                        <Outlet />
                    </div>
                </div>
            </Content>
        </Layout>
    );
};

export default DashboardLayout;
