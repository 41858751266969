import { useState } from 'react';
import { Layout, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import MainLogo from '../../assets/images/logo.svg';
import UserPlaceholder from '../../assets/images/user-placeholder.svg';
import LogoOnly from '../../assets/images/logo-only.svg';
import {
    UserOutlined,
    CrownOutlined,
    ProfileOutlined,
    InsertRowAboveOutlined,
    FormOutlined,
    ApartmentOutlined,
    SettingOutlined,
    // ProductOutlined, // for event list ƒ
    LogoutOutlined,
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import './template.css';
import { UserRole, useUser } from '@providers/UserProvider';
import { signOut } from '@supa/supabase-config';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import VersionInfo from '@components/atoms/VersionInfo';
import { secondaryGray } from '@modules/theme';
import { ItemType, MenuItemType } from 'antd/es/menu/interface';

const SideBar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { roles } = useUser();

    const { Sider } = Layout;
    const collapsed = false;
    // const [collapsed, setCollapsed] = useState(false);
    const [current, setCurrent] = useState(location.pathname.split('/')[1]);

    function menuItemVerified(
        label: string,
        key: string,
        icon: null | JSX.Element,
        role: UserRole, // FIXME: discuss user role logic and refactor this authorization logic
        children: ItemType<MenuItemType>[] | undefined,
        type?: 'group' | 'item' | undefined,
    ): ItemType<MenuItemType> {
        if (roles.includes(role)) {
            const item: ItemType<MenuItemType> = {
                key,
                icon,
                children: children as ItemType<MenuItemType>[],
                label,
                type,
            };
            return item;
        }
        return null;
    }

    const menuItems = [
        menuItemVerified(
            '',
            'top-menu',
            null,
            UserRole.STUDENT,
            [
                menuItemVerified(
                    'Dashboard',
                    'dashboard',
                    <ProfileOutlined />,
                    UserRole.STUDENT,
                    undefined,
                ),
            ],
            'group',
        ),
        menuItemVerified(
            'My Team',
            'my-team',
            null,
            UserRole.STUDENT,
            [
                menuItemVerified(
                    'Wrestlers',
                    'wrestlers',
                    <UserOutlined />,
                    UserRole.COACH,
                    undefined,
                ),
                // getItem("My Teams", "my-teams", <TeamOutlined />, ALL),
                menuItemVerified(
                    'Coaches',
                    'coaches',
                    <CrownOutlined />,
                    UserRole.COACH,
                    undefined,
                ),
                menuItemVerified(
                    'Settings',
                    'settings',
                    <SettingOutlined />,
                    UserRole.COACH,
                    undefined,
                ),
                // getItem("My Earnings", "earning", <DollarOutlined />, ALL),
                // getItem("Telepresence", "telepresence", <VideoCameraOutlined />, ALL),
            ],
            'group',
        ),

        menuItemVerified(
            'Manage Events',
            'OPERATION',
            null,
            UserRole.STUDENT,
            [
                menuItemVerified(
                    'Public Events',
                    'events',
                    <InsertRowAboveOutlined />,
                    UserRole.STUDENT,
                    undefined,
                ),
                menuItemVerified(
                    'My Events',
                    'events/manage-events',
                    <FormOutlined />,
                    UserRole.STUDENT,
                    undefined,
                ),
                menuItemVerified(
                    'My Registered Events',
                    'events/register-events',
                    <ApartmentOutlined />,
                    UserRole.STUDENT,
                    undefined,
                ),
            ],
            'group',
        ),
    ];

    const onClick = (e: { key: string }) => {
        setCurrent(e.key);
        navigate(`/${e.key}`);
    };

    return (
        <div className="sideb-main-wrapper">
            <Sider
                width={260}
                style={{
                    background: '#332D2D',
                    overflow: 'auto',
                }}
                collapsed={collapsed}
            >
                <Stack
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100vh"
                >
                    <Stack>
                        <div className={`side-bar-logo-wrapper  ${collapsed ? 'p-0' : ''}`}>
                            {!collapsed ? (
                                <span className="full-logo">
                                    <img src={MainLogo} />
                                </span>
                            ) : (
                                <span className="only-logo">
                                    <LogoOnly />
                                </span>
                            )}
                        </div>
                        <Menu
                            mode="inline"
                            onClick={onClick}
                            selectedKeys={[current]}
                            style={{ height: '100%', borderRight: 0 }}
                            items={menuItems}
                            className="side-bar-menu"
                        />
                    </Stack>
                    <Footer collapsed={collapsed} />
                </Stack>
            </Sider>
        </div>
    );
};

export default SideBar;

function Footer({ collapsed }: { collapsed: boolean }) {
    const { user } = useUser();

    let userName = user?.firstName || '';
    if (user && user?.lastName) {
        userName = `${user?.firstName} ${user?.lastName}`;
    }

    const handleLogout = () => {
        // FIXME: hook back up to redux action
        signOut();
        // dispatch(signOut());
    };

    return (
        <Stack>
            <VersionInfo small />
            <Box display="flex" justifyContent="center">
                <img src={UserPlaceholder} className="pe-2" />
                {collapsed ? null : (
                    <Typography sx={{ color: 'white', alignSelf: 'center' }}>{userName}</Typography>
                )}
            </Box>
            <Divider sx={{ margin: '1rem', borderColor: secondaryGray }} />
            <Button
                sx={{ marginBottom: '1rem' }}
                onClick={() => handleLogout()}
                startIcon={<LogoutOutlined />}
            >
                Logout
            </Button>
        </Stack>
    );
}
