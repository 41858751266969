import React, { useEffect, useState } from 'react';
import { Button, Table, Tag } from 'antd';
import useAuth from '../../hooks/useAuth';
import { getRegisterdWrestlersForEvent } from '../../api/services/event.service';
import { stripeService } from '../../api/services/stripe.services';

const RegisterWrestlersBulkEventsList = ({ eventDetail, setIsModalOpen }) => {
    const { user } = useUser();
    const schoolId = user.schoolId;

    const [defaultCurrent, setDefaultCurrent] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [wrestlerList, setWrestlerList] = useState([]);

    useEffect(() => {
        fetchWrestler(0, 10);
    }, []);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const fetchWrestler = async (offset, limit) => {
        setIsLoading(true);
        const eventId = eventDetail?.id;
        try {
            const query = `eventId=${eventId}&schoolId=${schoolId}&offset=${offset}&limit=${limit}`;
            const result = await getRegisterdWrestlersForEvent(query);
            setWrestlerList(result);
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false);
        }
    };

    const performPayment = async () => {
        setIsPaymentLoading(true);
        const paymentPayload = selectedRows.map((selectedUser) => {
            return {
                price_data: {
                    currency: 'usd',
                    product_data: {
                        name: `${selectedUser.user.firstName} ${selectedUser.user.lastName} registration in ${eventDetail.name}`,
                    },
                    unit_amount: eventDetail.fee * 100,
                },
                quantity: 1,
            };
        });

        const paymentIntentDataPayload = {
            metadata: {
                payment_type: 'tournament_entry_payment',
                eventId: eventDetail?.id,
                userIds: selectedRows.map((user) => user.userId).join(','),
            },
        };

        const payload = {
            lineItems: paymentPayload,
            payment_intent_data: paymentIntentDataPayload,
        };
        try {
            const response = await stripeService.doStripePayment(payload);
            window.location.href = response.url;
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsPaymentLoading(false);
            setIsLoading(false);
        }
    };

    const columns = [
        {
            title: 'Wrestler Name',
            dataIndex: 'name',
            key: 'name',
            render: (value, record) => {
                return (
                    <>
                        {record?.firstName} {record?.lastName}
                    </>
                );
            },
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
            render: (value) => {
                return <>{value}</>;
            },
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
        },
        {
            title: 'Fee',
            dataIndex: 'fee',
            key: 'fee',
            render: (value, record) => {
                return `$ ${eventDetail?.fee}`;
            },
        },
        {
            title: 'Payment Status',
            dataIndex: 'paymentStatus',
            key: 'paymentStatus',
            render: (value, record) => {
                return record?.paymentStatus === 'succeeded' ? (
                    <Tag color="success">Success</Tag>
                ) : (
                    <Tag color="error">Failed</Tag>
                );
            },
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record?.paymentStatus === 'succeeded',
        }),
    };

    return (
        <>
            <div className="table-wrapper mt-4 w-100">
                <Table
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    dataSource={wrestlerList?.data}
                    columns={columns}
                    rowKey="id"
                    scroll={{ y: 'calc(100vh - 500px)' }}
                    loading={isLoading}
                    pagination={{
                        current: defaultCurrent,
                        total: wrestlerList?.wrestlersData?.total || 0,
                        onChange: (page, pageSize) => {
                            setDefaultCurrent(page);
                            const offset = pageSize * (page - 1);
                            fetchWrestler(offset, pageSize);
                        },
                        showSizeChanger: false,
                    }}
                />
            </div>
            <div className="d-flex justify-content-end">
                <div className="d-flex gap-3 mt-3 w-50">
                    <Button
                        type="primary"
                        htmlType="button"
                        className="w-100 btn-outline rounded-pill"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="w-100 btn-primary rounded-pill"
                        disabled={selectedRows.length === 0}
                        loading={isPaymentLoading}
                        onClick={performPayment}
                    >
                        Pay Now
                    </Button>
                </div>
            </div>
        </>
    );
};

export default RegisterWrestlersBulkEventsList;
