import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { calculateAge } from '../../utils/helpers';
import {
    selectJoinBulkEventLoading,
    joinBulkEvent,
    getWrestlersForEvents,
    selectWrestlersForEventLoading,
    selectWrestlersForEvent,
    resetWrestlersForEvent,
} from './eventsSlice';
import WrestlersListTableForbulkEvent from './wrestlersListTableForbulkEvent';
import useAuth from '../../hooks/useAuth';

const JoinBulkEventsForm = ({ eventDetail, setIsModalOpen }) => {
    const dispatch = useDispatch();
    const schoolId = 'user.schoolId';

    const [defaultCurrent, setDefaultCurrent] = useState(1);
    const [isRegsterButtonDisabled, setIsRegsterButtonDisabled] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const [wrestlersDivision, setWrestlersDivision] = useState([]);
    const [wrestlersWeightClass, setwestlersWeightClass] = useState([]);
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [selectWavier, setSelectWavier] = useState(false);

    const isLoadingJoinBulkLoading = useSelector(selectJoinBulkEventLoading);
    const fetchWrestlersLoading = useSelector(selectWrestlersForEventLoading);
    const wrestlerList = useSelector(selectWrestlersForEvent);

    useEffect(() => {
        fetchWrestler(0, 10);
        return () => {
            dispatch(resetWrestlersForEvent());
        };
    }, []);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const fetchWrestler = (offset, limit) => {
        const eventId = eventDetail?.id;
        dispatch(getWrestlersForEvents({ offset, limit, eventId, schoolId }));
    };

    const handleBulkSubmit = () => {
        const payload = selectedRows.map((selectedUser) => {
            return {
                eventId: eventDetail?.id,
                userId: selectedUser?.accountId,
                firstName: selectedUser.firstName,
                lastName: selectedUser.lastName,
                age: calculateAge(selectedUser.dateOfBirth),
                weight: selectedUser.weight,
                division: getUserDivision(selectedUser?.id),
                weightClass: getUserWeight(selectedUser?.id),
                acceptedWaiver: true,
                schoolId,
            };
        });
        try {
            dispatch(joinBulkEvent(payload)).then((response) => {
                if (response.meta.requestStatus === 'fulfilled') {
                    setIsModalOpen(false);
                    window.location.href = response.payload.data.url;
                }
            });
        } catch (error) {}
    };

    const getUserDivision = (userId) => {
        const getUserDivisionName = wrestlersDivision.find((item) => item.wrestlerId === userId);
        return getUserDivisionName?.wrestlerDivision;
    };

    const getUserWeight = (userId) => {
        const getUserWeightClassName = wrestlersWeightClass.find(
            (item) => item.wrestlerId === userId,
        );
        return getUserWeightClassName?.wrestlerWeightClass;
    };
    console.log('selectWavier', selectWavier);
    return (
        <>
            <WrestlersListTableForbulkEvent
                isLoading={fetchWrestlersLoading}
                wrestlerList={wrestlerList}
                fetchWrestler={fetchWrestler}
                defaultCurrent={defaultCurrent}
                setDefaultCurrent={setDefaultCurrent}
                eventDetail={eventDetail}
                setIsRegsterButtonDisabled={setIsRegsterButtonDisabled}
                setSelectedRows={setSelectedRows}
                selectedRows={selectedRows}
                setWrestlersDivision={setWrestlersDivision}
                wrestlersDivision={wrestlersDivision}
                wrestlersWeightClass={wrestlersWeightClass}
                setwestlersWeightClass={setwestlersWeightClass}
            />

            <div className="d-flex justify-content-between align-items-center  mt-3">
                <div className="wavier-checkbox">
                    <Checkbox
                        onChange={(e) => {
                            setSelectWavier(!e.target.value);
                        }}
                        value={selectWavier}
                    >
                        Accept{' '}
                        <Button type="link" className="p-0" onClick={() => setShowTermsModal(true)}>
                            {' '}
                            waiver
                        </Button>
                    </Checkbox>
                    {!selectWavier && <span className="waiver-error">Please select waiver</span>}
                </div>
                <div className="d-flex gap-3 w-50">
                    <Button
                        type="primary"
                        htmlType="button"
                        className="w-100 btn-outline rounded-pill"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="w-100 btn-primary rounded-pill"
                        disabled={isRegsterButtonDisabled || !selectWavier}
                        loading={isLoadingJoinBulkLoading}
                        onClick={handleBulkSubmit}
                    >
                        Add Selected Wrestlers and pay
                    </Button>
                </div>
            </div>

            <Modal
                title={<span className="custom-heading">Waiver</span>}
                open={showTermsModal}
                footer={false}
                onCancel={() => setShowTermsModal(false)}
            >
                <div className="terms-modal">
                    {eventDetail && (
                        <div dangerouslySetInnerHTML={{ __html: eventDetail.waiver }}></div>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default JoinBulkEventsForm;
