import { Box, Fab, FabProps, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { memo } from 'react';

const FabSx: FabProps['sx'] = ({ palette }) => ({
    backgroundColor: palette.primary.main,
    '&:hover': {
        backgroundColor: palette.primary.dark,
    },
});

const FAB = ({ onClick, toolTip }: { onClick: () => void; toolTip: string }) => {
    return (
        <Box sx={{ position: 'absolute', zIndex: 100, bottom: 75, right: 50 }}>
            <Tooltip placement="left-end" title={toolTip}>
                <Fab onClick={onClick} sx={FabSx}>
                    <AddIcon sx={{ color: 'white' }} />
                </Fab>
            </Tooltip>
        </Box>
    );
};

export default memo(FAB);
