// src/form-component/FormInputText.tsx
import { Control, Controller, ControllerProps } from 'react-hook-form';
import TextField from '@mui/material/TextField';

export interface FormInputProps extends Omit<ControllerProps, 'name' | 'control' | 'render'> {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: Control<any, any>;
    label?: string;
}

const FormInputText = ({ name, control, label, ...rest }: FormInputProps) => {
    return (
        <Controller
            {...rest}
            name={name}
            control={control}
            render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => (
                <TextField
                    {...restField}
                    helperText={error ? error.message : null}
                    // size="small"
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    label={label}
                    margin="normal"
                    variant="outlined"
                />
            )}
        />
    );
};

export default FormInputText;
