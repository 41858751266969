// import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Form, Input, InputNumber, Select, Modal, Checkbox } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { savePaymentMethod } from '../../api/services/auth.service';
import useAuth from '../../hooks/useAuth';
// import { joinEvent } from './eventsSlice';
// import { toast } from 'react-toastify';
import { joinBulkEvent } from './eventsSlice';

const JoinEventForm = ({
    eventDetail,
    setIsModalOpen,
    setSelectedIdsFromProp,
    selectedIdsFromProp,
}) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);
    // const stripe = useStripe();
    // const elements = useElements();

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleBulkSubmit = (values) => {
        setIsLoading(true);
        const payload = {
            eventId: eventDetail?.id,
            userId: user?.accountId,
            firstName: values.firstName,
            lastName: values.lastName,
            age: values.age,
            weight: values.weight,
            division: values.division,
            weightClass: values.weightClass,
            acceptedWaiver: true,
        };
        try {
            dispatch(joinBulkEvent([payload])).then((response) => {
                if (response.meta.requestStatus === 'fulfilled') {
                    setIsModalOpen(false);
                    window.location.href = response.payload.data.url;
                }
            });
        } catch (error) {
            setIsLoading(false);
        }
    };

    // const onFinish = async (values) => {
    //     setIsLoading(true);
    //     if (!stripe || !elements) {
    //         setIsLoading(false);
    //         console.log('Stripe has not loaded yet!');
    //         return;
    //     }

    //     const paymentIntentPayload = {
    //         amount: eventDetail?.fee * 100,
    //         currency: 'usd',
    //         'payment_method_types[]': 'card',
    //         'automatic_payment_methods[enabled]': 'true',
    //         'automatic_payment_methods[allow_redirects]': 'never',
    //         metadata: {
    //             payment_type: 'tournament_entry_payment',
    //             eventId: eventDetail?.id,
    //             userIds: user?.accountId,
    //         },
    //     };

    //     await dispatch(createPaymentIntent({ payload: paymentIntentPayload })).then(
    //         (action) => {
    //             if (action.meta.requestStatus === 'fulfilled') {
    //                 handleStripeConfirmation(values, action.payload);
    //             } else {
    //                 setIsLoading(false);
    //                 console.error('Error in payment intent creation:', action.error);
    //                 toast.error(action.error);
    //             }
    //         }
    //     );
    // };

    // const handleStripeConfirmation = async (values, setupIntentData) => {
    //     const cardElement = elements.getElement(CardElement);

    //     try {
    //         const result = await stripe.confirmCardPayment(
    //             setupIntentData.client_secret,
    //             {
    //                 payment_method: {
    //                     card: cardElement,
    //                     billing_details: {
    //                         name: `${values.firstName} ${values.lastName}`,
    //                     },
    //                 },
    //             }
    //         );

    //         if (result.error) {
    //             setIsLoading(false);
    //             console.error('Error:', result.error.message);
    //             toast.error(result.error.message);
    //         } else {
    //             const payload = {
    //                 customerId: user?.customerId,
    //                 paymentMethodId: result?.paymentIntent?.payment_method,
    //             };
    //             await savePaymentMethod(payload);
    //             registerEvent(values);
    //         }
    //     } catch (error) {
    //         setIsLoading(false);
    //         console.error('Error during Stripe card confirmation:', error);
    //         toast.error(error);
    //     }
    // };

    // const registerEvent = (values) => {
    //     const payload = {
    //         eventId: eventDetail?.id,
    //         userId: user?.accountId,
    //         ...values,
    //         acceptedWaiver: true,
    //     };

    //     dispatch(joinEvent(payload)).then((response) => {
    //         if (response.meta.requestStatus === 'fulfilled') {
    //             setIsLoading(false);
    //             setIsModalOpen(false);
    //             const updatedIds = [...selectedIdsFromProp, eventDetail?.id];
    //             setSelectedIdsFromProp(updatedIds);
    //         }
    //     });
    // };

    return (
        <>
            <Form
                name="joinEventform"
                initialValues={{
                    eventJoinee: [''],
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                }}
                onFinish={handleBulkSubmit}
                autoComplete="off"
                layout={'vertical'}
                className="mt-4"
            >
                <div className="row">
                    <div className="col-6">
                        <Form.Item
                            label="Wrestler first name"
                            name={'firstName'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input wrestler first name',
                                },
                            ]}
                        >
                            <Input size="large" placeholder="Enter first name" />
                        </Form.Item>
                    </div>
                    <div className="col-6">
                        <Form.Item
                            label="Wrestler last name"
                            name={'lastName'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input wrestler last name',
                                },
                            ]}
                        >
                            <Input size="large" placeholder="Enter last name" />
                        </Form.Item>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Form.Item
                            label="Wrestler age"
                            name={'age'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input wrestler age',
                                },
                            ]}
                        >
                            <InputNumber size="large" placeholder="Enter wrestler age" />
                        </Form.Item>
                    </div>
                    <div className="col-6">
                        <Form.Item
                            label="Wrestler weight"
                            name={'weight'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input wrestler weight',
                                },
                            ]}
                        >
                            <InputNumber size="large" placeholder="Enter wrestler weight" />
                        </Form.Item>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Form.Item
                            label="Division"
                            name={'division'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select division',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select Division"
                                options={eventDetail?.divisions.map((division) => {
                                    return {
                                        value: division?.divisionName,
                                        label: division?.divisionName,
                                    };
                                })}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-6">
                        <Form.Item
                            label="Weight class"
                            name={'weightClass'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select weight class',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select weight class"
                                options={eventDetail?.weightClasses.map((weightClass) => {
                                    return {
                                        value: weightClass?.className,
                                        label: weightClass?.className,
                                    };
                                })}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-12">
                        <div className="col-md-6">
                            <Form.Item
                                name="wavier"
                                valuePropName="checked"
                                rules={[{ required: true, message: 'Please accept wavier!' }]}
                            >
                                <Checkbox>
                                    Accept{' '}
                                    <Button
                                        type="link"
                                        className="p-0"
                                        onClick={() => setShowTermsModal(true)}
                                    >
                                        wavier
                                    </Button>
                                </Checkbox>
                            </Form.Item>
                        </div>
                    </div>
                </div>
                {/* <Form.Item label='Card Details'>
                <div className='payment-box'>
                    <CardElement options={{ hidePostalCode: true }} />
                </div>
            </Form.Item> */}
                <div className="d-flex mb-3">
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="w-100 btn-primary rounded-pill"
                        loading={isLoading}
                    >
                        Register and Pay
                    </Button>
                </div>
                <div className="d-flex gap-3">
                    <Button
                        type="primary"
                        htmlType="button"
                        className="w-100 btn-outline rounded-pill"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </div>
            </Form>

            <Modal
                title={<span className="custom-heading">Waiver</span>}
                open={showTermsModal}
                footer={false}
                onCancel={() => setShowTermsModal(false)}
            >
                <div className="terms-modal">
                    {eventDetail && (
                        <div dangerouslySetInnerHTML={{ __html: eventDetail.waiver }}></div>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default JoinEventForm;
