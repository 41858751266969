import httpService from '../httpClient/httpService';

export const getLoginDetails = async (payload) => {
    const response = await httpService.post('auth/login', payload);
    return response.data;
};

export const userSignup = async (payload) => {
    const response = await httpService.post('auth/signup', payload);
    return response.data;
};

export const getSchoolsName = async () => {
    const response = await httpService.get('school/search');
    return response.data;
};
// stripe
export const createSetupIntent = async (payload) => {
    const response = await httpService.post('stripe/create-setup-intent', payload);
    return response.data;
};
export const createPaymentIntent = async (payload) => {
    const response = await httpService.post('stripe/create-payment-intent', payload);
    return response.data;
};
export const savePaymentMethod = async (payload) => {
    const response = await httpService.post('stripe/save-payment-method', payload);
    return response.data;
};

export const authService = {
    getLoginDetails,
    userSignup,
    getSchoolsName,
    createSetupIntent,
    createPaymentIntent,
};
