import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { store } from './store/store';
import { Provider } from 'react-redux';
import './App.css';
import { initializeSentry } from './utils/logging.ts';

initializeSentry();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <Sentry.ErrorBoundary
        fallback={<p>Sorry, an error has occurred. Refresh your browser and try again</p>}
    >
        <StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <ToastContainer theme="colored" pauseOnHover closeOnClick autoClose={3000} />
                    <App />
                </BrowserRouter>
            </Provider>
        </StrictMode>
    </Sentry.ErrorBoundary>,
);
