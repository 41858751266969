import React, { useEffect, useState, useRef } from 'react';
import Header from '../../components/layout/header';
import './events.css';
import {
    selectEventDetailsLoading,
    selectEventDetails,
    getEventDetails,
    resetEventDetails,
} from './eventsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Button, Modal } from 'antd';
import eventBg from '../../assets/images/event-bg.jpg';
import { Calendar, MapPin, Clock } from 'react-feather';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import JoinEventForm from './joinEventForm';
import JoinBulkEventsForm from './joinBulkEventsForm';
import { useUser } from '../../providers/UserProvider';
import { SUPER_COACH, COACH, STUDENT } from '../../utils/rolesConfig';
import { CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QRCode from 'react-qr-code';
import { toPng } from 'html-to-image';
import { hasDatePassed } from '../../utils/helpers';

const EventsDetails = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const qrRef = useRef();

    const isLoading = useSelector(selectEventDetailsLoading);
    const eventDetails = useSelector(selectEventDetails);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const isJoined = searchParams.get('isJoined');

    const [showBulkRegistrationModal, setShowBulkRegistrationModal] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { loading, session, user } = useUser();
    const role = user.role;

    const showModal = () => {
        setIsModalOpen(true);
    };

    const [copyText, setCopyText] = useState('Copy Event Link');
    const [copyQRText, setCopyQRText] = useState('Copy Event QR Code');
    const copyLink = eventDetails
        ? `${window.location.origin}/events/event-details/${eventDetails.id}`
        : '';

    useEffect(() => {
        if (id) {
            dispatch(getEventDetails(id));
        }

        return () => {
            dispatch(resetEventDetails());
        };
    }, [id]);

    const handleCopyText = () => {
        setCopyText('Copied!');
        setTimeout(() => {
            setCopyText('Copy Event Link');
        }, 2000);
    };

    const copyToClipboard = () => {
        setCopyQRText('Copied!');
        const svg = qrRef.current.querySelector('svg');
        if (svg) {
            toPng(svg)
                .then((dataUrl) => {
                    fetch(dataUrl)
                        .then((res) => res.blob())
                        .then((blob) => {
                            const item = new ClipboardItem({ 'image/png': blob });
                            navigator.clipboard.write([item]);
                        });
                })
                .catch((err) => {
                    console.error('Failed to copy QR code', err);
                });
        }
        setTimeout(() => {
            setCopyQRText('Copy Event QR Code');
        }, 2000);
    };
    console.log('eventDetails', eventDetails);

    return (
        <>
            <Header pageTitle={'Event Detail'} />
            {!isLoading && (
                <div className="event-header">
                    <div className="event-bg-wrapper">
                        <img src={eventBg} alt="" />
                    </div>
                    <div className="event-details my-event-details">
                        <div className="event-card-time-locaion">
                            <p>
                                <span className="icon">
                                    <Calendar />
                                </span>{' '}
                                {eventDetails?.date}
                            </p>
                            <h3>{eventDetails?.name}</h3>
                            <p>
                                <span className="icon">
                                    <MapPin />{' '}
                                </span>{' '}
                                {eventDetails?.location}
                            </p>
                        </div>
                    </div>
                </div>
            )}
            <div className="main-container-inner my-event-details-containter p-5">
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <Spin size="large" />
                    </div>
                ) : (
                    <>
                        <div className="my-event-sub-details-wrapper">
                            <div className="event-sub-inner">
                                <div className="event-sub-left">
                                    <div className="event-timing-left">
                                        <div className="event-sub-left-inner">
                                            <h6>Weigh Ins Start Time</h6>
                                            <p className="m-0">
                                                {' '}
                                                <Clock size={16} /> {eventDetails?.startTimeWeighIn}
                                            </p>
                                        </div>
                                        <div className="event-sub-left-inner">
                                            <h6>Wrestling Start Time</h6>
                                            <p className="m-0">
                                                {' '}
                                                <Clock size={16} /> {eventDetails?.startTimeBegin}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="event-fee-left">
                                        <div className="event-sub-left-inner">
                                            <h6>Event Fee</h6>
                                            <p className="m-0">${eventDetails?.fee}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="event-sub-right">
                                    <CopyToClipboard
                                        text={copyLink}
                                        onCopy={() => handleCopyText()}
                                    >
                                        <Button
                                            type="primary"
                                            className="btn-primary rounded-pill d-flex align-items-center"
                                        >
                                            {copyText === 'Copied!' ? (
                                                <CheckOutlined />
                                            ) : (
                                                <CopyOutlined />
                                            )}
                                            {copyText}
                                        </Button>
                                    </CopyToClipboard>

                                    <div
                                        ref={qrRef}
                                        style={{
                                            position: 'absolute',
                                            top: '-9999px',
                                            left: '-9999px',
                                        }}
                                    >
                                        <QRCode value={copyLink} />
                                    </div>
                                    <h3>OR</h3>
                                    <Button
                                        type="primary"
                                        className="btn-primary rounded-pill d-flex align-items-center"
                                        onClick={copyToClipboard}
                                    >
                                        {copyQRText === 'Copied!' ? (
                                            <CheckOutlined />
                                        ) : (
                                            <CopyOutlined />
                                        )}
                                        {copyQRText}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="my-event-details-wrapper">
                            <div className="event-details-wrapper left">
                                <div className="event-description">
                                    <div className="event-description">
                                        <h3>Event Description</h3>
                                        <p>{eventDetails?.description}</p>
                                    </div>
                                </div>
                                <div className="d-flex gap-3 mt-3 mb-2">
                                    {role === STUDENT[0] && (
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className={
                                                isJoined === 'true'
                                                    ? 'w-25 btn-primary rounded-pill pe-none'
                                                    : 'w-25 btn-primary rounded-pill'
                                            }
                                            onClick={showModal}
                                            disabled={
                                                isJoined === 'true' ||
                                                hasDatePassed(eventDetails?.date)
                                            }
                                        >
                                            {isJoined === 'true'
                                                ? 'Already register'
                                                : hasDatePassed(eventDetails?.date)
                                                  ? 'Registeration Closed'
                                                  : 'Register Now'}
                                        </Button>
                                    )}
                                    {(role === COACH[0] || role === SUPER_COACH[0]) && (
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="w-25 btn-primary rounded-pill"
                                            onClick={() => {
                                                setShowBulkRegistrationModal(true);
                                            }}
                                            disabled={hasDatePassed(eventDetails?.date)}
                                        >
                                            {hasDatePassed(eventDetails?.date)
                                                ? 'Registeration Closed'
                                                : ' Register Wrestlers'}
                                        </Button>
                                    )}
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="w-25 btn-outline rounded-pill"
                                        onClick={() => {
                                            navigate(`/events`);
                                        }}
                                    >
                                        Go back
                                    </Button>
                                </div>
                            </div>
                            <div className="event-details-wrapper right">
                                <div className="event-sub-left-inner">
                                    <h6>Location</h6>
                                    <p className="m-0">{eventDetails?.location}</p>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <Modal
                title={
                    <span className="custom-heading">
                        Add wrestlers to {eventDetails?.name} - ${eventDetails?.fee}
                    </span>
                }
                open={showBulkRegistrationModal}
                width={'1200px'}
                footer={false}
                destroyOnClose={true}
                onCancel={() => setShowBulkRegistrationModal(false)}
            >
                {/* <StripeContainer clientSecret={clientSecret}> */}
                <JoinBulkEventsForm
                    eventDetail={eventDetails}
                    setIsModalOpen={setShowBulkRegistrationModal}
                />
                {/* </StripeContainer> */}
            </Modal>

            <Modal
                title={
                    <span>
                        Register yourself for this {eventDetails?.name} - ${eventDetails?.fee}
                    </span>
                }
                open={isModalOpen}
                footer={false}
                destroyOnClose={true}
                onCancel={() => setIsModalOpen(false)}
            >
                {/* <StripeContainer clientSecret={clientSecret}> */}
                <JoinEventForm eventDetail={eventDetails} setIsModalOpen={setIsModalOpen} />
                {/* </StripeContainer> */}
            </Modal>
        </>
    );
};

export default EventsDetails;
