import { supabase } from './supabase-config';
import invariant from 'tiny-invariant';

import { breadcrumb, log } from '@utils/logging';
import { getSignedInUser } from './supabase-utils';
import { toast } from 'react-toastify';
import { Athlete, TeamMember, User, UserMembership } from '@type/db-types';

const USER_TEAM = 'user_team';
const TEAM_MEMBERSHIP = 'TeamMembership'; // View, NOT table
const USERS = 'users';
const ATHLETES = 'athletes';

export const getUser = async (userId: string): Promise<User> => {
    const user = await getSignedInUser();
    invariant(user, 'User must be signed in to get users');

    const query = supabase.from(USERS).select('*').eq('id', userId);

    const { data, error } = await query;
    if (error) throw Error(error.message);
    breadcrumb('getUserById', { userId, data });
    return data[0];
};

export async function updateUser(
    user: Pick<User, 'id' | 'firstName' | 'lastName'>,
    description: string,
): Promise<User | null> {
    breadcrumb('updateUser', { user, description });

    const { firstName, lastName, id } = user;

    const { data, error } = await supabase
        .from(USERS)
        .update({ firstName, lastName, id })
        .eq('id', user.id);

    if (error) {
        log(`[updateUser]: ${error.message}`, { error, user, description });
        throw new Error(error.message);
    } else {
        console.log(`user (${description}) updated `, data);
        return data;
    }
}

async function updateUserMembership(membership: TeamMember): Promise<UserMembership | null> {
    const { membershipId, role, teamStatus } = membership;
    const { data, error } = await supabase
        .from(USER_TEAM)
        .update({ role, status: teamStatus })
        .eq('id', membershipId);

    if (error) {
        log(`[updateUserMembership]: ${error.message}`, { error, membership });
        throw new Error(error.message);
    } else {
        console.log('user membership updated ', data);
        return data;
    }
}

async function updateUserAndMembership(
    user: TeamMember,
    description = 'updateUser',
): Promise<User | null> {
    breadcrumb('updateUser', { user, description });

    const { firstName, lastName, id, phone } = user;

    const { data, error } = await supabase
        .from(USERS)
        .update({ firstName, lastName, id, phone })
        .eq('id', user.id);

    updateUserMembership(user);

    if (error) {
        log(`[updateUser]: ${error.message}`, { error, user, description });
        throw new Error(error.message);
    } else {
        console.log(`user (${description}) updated `, data);
        return data;
    }
}
export async function updateAthlete(
    athlete: Athlete,
    description = 'updateAthlete',
): Promise<User | null> {
    return toast.promise(
        async () => {
            breadcrumb('updateAthlete', { athlete, description });

            invariant(athlete.id, 'Athlete must have an id to update');

            // update user first
            updateUserAndMembership(athlete); // throws on error, handled by toast

            const { birthday, weight, gender } = athlete;
            const { data, error } = await supabase
                .from(ATHLETES)
                .update({ birthday, weight, gender })
                .eq('id', athlete.id);

            if (error) {
                log(`[updateAthlete]: ${error.message}`, { error, athlete, description });
                throw new Error(error.message);
            } else {
                console.log(`(${description}) success `, data);
                return data;
            }
        },
        {
            pending: 'Updating athlete...',
            success: 'Athlete updated',
            error: 'Error updating athlete',
        },
    );
}

export const getTeamAthletes = async (teamId: string | undefined): Promise<Athlete[]> => {
    if (!teamId) return [];

    const { data, error } = await supabase
        .from('athletes')
        .select(
            `
            userId:id,
            *,
            ...TeamMembership!userId(*)
            
    `,
        )
        .eq('TeamMembership.teamId', teamId);

    if (error) {
        log(`[getTeamAthletes]: ${error.message}`);
        toast('Error fetching athletes');
        return [];
    }

    breadcrumb('getTeamAthletes', { teamId, data });

    return data as Athlete[];
};
export const getTeamMembers = async (teamId: string | undefined): Promise<TeamMember[]> => {
    if (!teamId) return [];

    const { data, error } = await supabase.from(TEAM_MEMBERSHIP).select('*').eq('teamId', teamId);

    if (error) {
        log(`[getTeamMembers]: ${error.message}`);
        toast('Error fetching team members');
        return [];
    }

    breadcrumb('getTeamMembers', { teamId, data });

    return data as TeamMember[];
};
