import httpService from '../httpClient/httpService';

export const doStripePayment = async (payload) => {
    const response = await httpService.post('stripe/checkout', payload);
    return response.data;
};

export const stripeService = {
    doStripePayment,
};
