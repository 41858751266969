import { signInWithEmail, useSignInWithGoogle } from '@supa/auth';
import { useForm, SubmitHandler } from 'react-hook-form';
import FormInputText from '@components/forms/FormInputText';
import { Button, Stack } from '@mui/material';
import { memo, useEffect } from 'react';
import { toast } from 'react-toastify';
import { breadcrumb, logError } from '@utils/logging';
import useAuth from '@hooks/useAuth';
import { useNavigate } from 'react-router-dom';

// NOTE: This is a workaround for the Google Sign-In button - https://stackoverflow.com/a/71421555
declare global {
    interface Window {
        handleCredentialResponse?: unknown;
    }
}

interface Inputs {
    email: string;
}

const Login = () => {
    const navigate = useNavigate();
    const { loading, session } = useAuth();
    const { control, handleSubmit } = useForm<Inputs>();

    const onSubmit: SubmitHandler<Inputs> = (values) => {
        console.log(`Login onSubmit`, { values });
        toast
            .promise(signInWithEmail(values.email), {
                pending: 'Preparing email link...',
                success: 'Email Sent! Check you inbox for the login link',
                error: 'Error sending email login link',
            })
            .catch((error: unknown) => {
                breadcrumb('Error sending email login link', { error });
                if (error instanceof Error) logError(error);
            });
    };

    // console.log(`Login loading`, { loading, session, authUser });

    useEffect(() => {
        if (session) {
            // Redirect to home page
            navigate('/wrestlers', { replace: true });
        }
    }, [navigate, session]);

    return loading ? null : (
        <>
            <div className="form-header">
                <h3>Sign in</h3>
            </div>
            <div className="form-container">
                {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <GoogleSignInButton />

                    <Stack>
                        <FormInputText
                            name="email"
                            control={control}
                            label="Email"
                            rules={{
                                required: true,
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: 'Please enter a valid email',
                                },
                            }}
                        />

                        <Stack>
                            <Button
                                sx={{ alignSelf: 'center', width: '12rem', marginTop: '1.5rem' }}
                                type="submit"
                                variant="contained"
                            >
                                Login
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </div>
        </>
    );
};

const GoogleSignInButton = memo(() => {
    const { googleHtmlNonce, onSignInWithGoogle } = useSignInWithGoogle();

    // NOTE: This is a workaround for the Google Sign-In button - https://stackoverflow.com/a/71421555
    window.handleCredentialResponse = onSignInWithGoogle;

    return googleHtmlNonce ? (
        <>
            <div
                key={`g_id_onload_${googleHtmlNonce}`}
                id="g_id_onload"
                data-client_id="974956733042-m04onqjvib6or8b0u30jtil55bt0n7vl.apps.googleusercontent.com"
                data-context="signin"
                data-ux_mode="popup"
                data-callback="handleGoogleCredentialResponse"
                data-nonce={googleHtmlNonce}
                data-auto_prompt="false"
                data-use_fedcm_for_prompt="true"
            ></div>

            <div
                key={`g_id_signin_${googleHtmlNonce}`}
                // class="g_id_signin"
                className="g_id_signin"
                data-type="standard"
                data-shape="rectangular"
                data-theme="outline"
                data-text="signin_with"
                data-size="large"
                data-logo_alignment="left"
                data-width="250"
            ></div>
        </>
    ) : null;
});

export default Login;
